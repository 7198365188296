import * as React from 'react';
import EssayPackages from './Essays/EssayPackages';
import { EssayPackage, EssayReturnTimeTier } from '../../Types/Essays';
import ReturnTimeTiers from './Essays/ReturnTimeTiers';

type Props =
  { essayPackages: EssayPackage[]
  , returnTimeTiers: EssayReturnTimeTier[]
  }

const Essays = (props: Props) => (
  <div>
    <EssayPackages essayPackages={props.essayPackages} />
    <div className="mt-3" />
    <ReturnTimeTiers returnTimeTiers={props.returnTimeTiers} />
  </div>
)

export default Essays;
