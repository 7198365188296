import * as React from 'react';
import { useState, useEffect } from 'react';
import * as API from '../Utils/API.js';
import { Advisor, advisorProfilePath } from '../Types/Advisor';
import { htmlIf, formatMultiParagraphString, maybeHtml } from '../Utils/HTML';
import { formatUSDWithCents, formatUSDWithoutCents } from '../Utils/Currency';
import * as EmailValidator from 'email-validator';
import { AdvisingSession } from '../Types/Advising';
import Schedule, { Layout } from './Schedule';


type Props =
  { advisor: Advisor
  , advisingSessions: AdvisingSession[]
  , availability: string[]
  , isAnonymous: boolean
  }

enum Step
  { SelectSession
  , SelectTime
  , ConfirmPurchase
  }

const SingleSessionRefundPeriodLengthDays = 7

function formatDateTime(date: Date) {
  return date.toLocaleDateString('en-US',{
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short'
  })
}

const SessionCheckout = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(Step.SelectSession);
  const [completedSteps, setCompletedSteps] = useState(new Set<Step>());

  const [showErrors, setShowErrors] = useState(false);

  const [selectedSession, setSelectedSession] = useState<AdvisingSession>(props.advisingSessions[0] ?? null);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date(props.availability[0]));
  const [selectedTime, setSelectedTime] = useState<Date>();

  const [sessionGoals, setSessionGoals] = useState('');

  const [anonFirstName, setAnonFirstName] = useState('');
  const [anonLastName, setAnonLastName] = useState('');
  const [anonEmail, setAnonEmail] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });

    setShowErrors(false);
  }, [currentStep])

  function purchaseSession() {
    setIsSubmitting(true);

    const postBody = {
      advisingSessionId: selectedSession.id,
      startTimeUtc: selectedTime.toISOString(),
      sessionGoals: sessionGoals,
      anonEmail: anonEmail,
      anonFirstName: anonFirstName,
      anonLastName: anonLastName
    }

    API.post("advising_purchase_single_session_path", postBody).then(function (result) {
      setIsSubmitting(false);

      if (result['error']) {
        if (result['message']) {
          setErrorMessage(result['message'])
        } else {
          setErrorMessage("Something went wrong. Our team has been notified and will investigate the issue.")
        }
      } else {
        window.location.href = result['url']
      }
    })
  }

  const ViewAdvisorDetails = () => (
    <div className="p-3 pb-0 rounded-2">
      <div className="fs-lg fw-semibold text-dark">Your advisor</div>
      <a className="d-flex align-items-center mt-1 text-decoration-none text-dark cursor-pointer"
        href={advisorProfilePath(props.advisor.id)} target="_blank"
      >
        <div className="avatar avatar-xl">
          <img className="avatar-img rounded-circle" src={props.advisor.imageUrl}/>
        </div>
        <div className="ms-1">
          <div className="fs-md ms-1">{props.advisor.preferredName}</div>
          {htmlIf((props.advisor.averageRating !== null && props.advisor.numRatings > 1),
            <div className="d-flex align-items-center">
              <i className="ai-star me-1 text-primary"/>
              {props.advisor.averageRating?.toFixed(1)}
            </div>
          )}
        </div>
      </a>
    </div>
  )

  const ViewProgressNav = () => {
    const steps = [
      { number: 1, title: 'Select a session', icon: 'ai-grid', step: Step.SelectSession },
      { number: 2, title: 'Choose a time', icon: 'ai-calendar', step: Step.SelectTime },
      { number: 3, title: 'Confirm & Submit', icon: 'ai-check', step: Step.ConfirmPurchase }
    ]

    function navItemClickHandler(event: React.MouseEvent<HTMLAnchorElement>, step: Step) {
      if (completedSteps.has(step)) {
        event.preventDefault();
        setCurrentStep(step);
      } else {
        return
      }
    }

    return (
      <>
        <ViewAdvisorDetails />
        <div className="card-header mt-2">
          {steps.map((stepInfo, index) => (
            <div className={`${currentStep === index ? "bg-white border rounded-2" : ""} ${index > 0 ? "mt-1" : ""}`} key={index}>
              <a className={`px-2 py-1 d-flex align-items-center justify-content-between text-decoration-none
                  ${completedSteps.has(stepInfo.step) ? 'cursor-pointer' : ''}`}
                key={index}
                onClick={(e) => navItemClickHandler(e, stepInfo.step)}
              >
                <div className="d-flex align-items-center">
                  <i className={`${stepInfo.icon} h4 mb-0 me-2 ${currentStep === index ? "text-primary" : completedSteps.has(stepInfo.step) ? "text-gray-900" : "text-gray-600"}`} />
                  <div className={`fs-md ${currentStep === index || completedSteps.has(stepInfo.step) ? "text-dark" : "text-gray-700"} text-nowrap`}>
                    {stepInfo.title}
                  </div>
                </div>
                {htmlIf(stepInfo.number !== 3,
                  <div className="avatar avatar-xs me-2">
                    <div className={`avatar-title mb-0 ${completedSteps.has(stepInfo.step) ? "bg-primary" : "bg-gray-300"} rounded-circle text-white h6`}>
                      <i className="ai-check" />
                    </div>
                  </div>
                )}
              </a>
            </div>
          ))}
        </div>
      </>
    )
  }

  const ViewSelectSessionPage = () => {
    function handleNextClicked() {
      if (selectedSession) {
        setCurrentStep(Step.SelectTime);
        setCompletedSteps(completedSteps.add(Step.SelectSession));
      } else {
        setShowErrors(true);
      }
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0 py-2">
            <div className="fs-md">Step 1 of 3</div>
            <div className="h2 mb-0 text-dark">Let’s get started</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <h6 className="fw-bold mb-3">What kind of support are you looking for?</h6>
            <div className="row mb-n2">
              {props.advisingSessions.map((session) => (
                <div className="col-md-6 mt-2 mt-md-0 mb-2">
                  <div className={`card h-100 rounded-2 cursor-pointer t--session-${session.id} ${selectedSession?.id === session.id ? " border-primary" : ""}`}
                    onClick={() => setSelectedSession(session)}
                  >
                    <div className="card-body p-3">
                      <h5 className={"mb-1 " + (selectedSession?.id === session.id ? "text-primary" : "")}>{session.name}</h5>
                      <div className="fs-sm text-dark">
                        {session.numMinutes} minutes — {formatUSDWithCents(session.amountCents)}
                      </div>
                      <div className="fs-sm mt-1">{session.description}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {htmlIf(showErrors && !selectedSession,
              <div className="fs-sm mt-1 text-danger fw-bold">Please select a session.</div>
            )}
          </div>
          <div className="card-footer d-flex border-0">
            <button className="btn btn-primary px-6 t--step-1-next t--select-session-next"
              onClick={handleNextClicked}
              disabled={!selectedSession}
            >
                Next
            </button>
          </div>
        </div>
      </div>
    )
  }

  const ViewSelectTimePage = () => {
    function handleNextClicked() {
      setCurrentStep(Step.ConfirmPurchase);
      setCompletedSteps(completedSteps.add(Step.SelectTime));
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0 py-2">
            <div className="fs-md">Step 2 of 3</div>
            <div className="h2 mb-0 text-dark">Choose a time for your session</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <h5 className="fw-bold mb-3">Session length: {selectedSession.numMinutes} minutes</h5>
            <Schedule
              layout={Layout.Wide}
              availableDateTimes={props.availability}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              onSelectDate={setSelectedDate}
              onSelectTime={setSelectedTime}
            />
          </div>
          <div className="card-footer d-flex border-0">
            <button onClick={() => setCurrentStep(Step.SelectSession)} className="btn btn-outline-secondary">
              Back
            </button>
            <button className="btn btn-primary px-6 ms-2 t--step-2-next t--select-time-next"
              onClick={handleNextClicked}
              disabled={!selectedTime}
            >
                Next
            </button>
          </div>
        </div>
      </div>
    )
  }

  const ViewAnonymousDetailEntry = () => (
    <>
      <div className="fs-md fw-semibold">Before you book your session, let’s make sure we know how to get in touch with you!</div>
      <div className="row mt-2">
        <div className="col-md-6">
          <h6 className="fw-bold mb-0">First name*</h6>
          <input
            className="form-control mt-1 t--anon-first-name"
            placeholder="Enter first name…"
            value={anonFirstName}
            onChange={(event) => setAnonFirstName(event.target.value)}
          />
          {htmlIf(showErrors && !anonFirstName.length,
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter your first name.</div>
          )}
        </div>
        <div className="col-md-6 mt-2 mt-md-0">
          <h6 className="fw-bold mb-0">Last name*</h6>
          <input
            className="form-control mt-1 t--anon-last-name"
            placeholder="Enter last name…"
            value={anonLastName}
            onChange={(event) => setAnonLastName(event.target.value)}
          />
          {htmlIf(showErrors && !anonLastName.length,
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter your last name.</div>
          )}
        </div>
        <div className="col mt-2">
          <h6 className="fw-bold mb-0">Email address*</h6>
          <input
            className="form-control mt-1 t--anon-email"
            placeholder="Enter email…"
            value={anonEmail}
            onChange={(event) => setAnonEmail(event.target.value)}
          />
          {htmlIf(showErrors && !EmailValidator.validate(anonEmail),
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter a valid email address.</div>
          )}
        </div>
      </div>
    </>
  )

  const ViewConfirmPurchasePage = () => {
    function handleSubmitPackageClicked() {
      if (sessionGoals.length > 10) {
        if (props.isAnonymous) {
          if (EmailValidator.validate(anonEmail) && anonFirstName.length > 0 && anonLastName.length > 0) {
            purchaseSession();
          } else {
            setShowErrors(true);
          }
        } else {
          purchaseSession();
        }
      } else {
        setShowErrors(true);
      }
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0 py-2">
            <div className="fs-md">Step 3 of 3</div>
            <div className="h2 mb-0 text-dark">Book your session</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
              {htmlIf(props.isAnonymous,
                <div className="mb-3 pb-2 border-bottom">
                  {ViewAnonymousDetailEntry()}
                </div>
              )}
              <div className="mb-3">
                <h6 className="fw-bold mb-0">What are your goals for this session?</h6>
                <textarea
                  className="form-control mt-1 t--session-goals"
                  rows={3}
                  placeholder="Adding a note here helps your advisor plan for your session…"
                  value={sessionGoals}
                  onChange={(event) => setSessionGoals(event.target.value)}
                />
                {htmlIf(showErrors && !(sessionGoals.length > 10),
                  <div className="fs-sm mt-1 text-danger fw-bold">Please provide more information to your advisor.</div>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                    <div className="fs-md fw-semibold text-gray-900">Session</div>
                    <div className="fs-md text-primary text-uppercase">{selectedSession.name}</div>
                  </div>
                <div className="col-md-6">
                    <div className="fs-md fw-semibold text-gray-900">Advisor</div>
                    <div className="fs-md">{props.advisor.preferredName}</div>
                  </div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">Scheduled For</div>
                <div className="fs-md">{formatDateTime(selectedTime)}</div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">Session Length</div>
                <div className="fs-md">{selectedSession.numMinutes} minutes</div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">Refund Policy</div>
                <div className="fs-md">You may request a refund of any unused sessions within {SingleSessionRefundPeriodLengthDays} days of purchase.</div>
              </div>
              <div className="fs-lg fw-bold mt-2 text-dark">Summary</div>
              <div className="mt-1 mb-2 text-dark">
                <div className="row">
                  <div className="col">Total Price</div>
                  <div className="col-auto text-end fw-bold">{formatUSDWithCents(selectedSession.amountCents)}</div>
                </div>
              </div>
              <div className="mt-3 fs-sm text-dark">
                Clicking below will take you to Stripe to enter payment information.
              </div>
          </div>
          <div className="card-footer border-0">
            <div className="d-flex">
              <button onClick={() => setCurrentStep(Step.SelectTime)} className="btn btn-outline-secondary">
                Back
              </button>
              <button className="btn btn-primary px-6 ms-2 t--submit-and-pay t--confirm-purchase-next"
                onClick={handleSubmitPackageClicked}
                disabled={isSubmitting}
              >
                  Submit & Pay
              </button>
            </div>
            {htmlIf(errorMessage !== null,
              <div className="mt-2 text-danger fw-bold">{errorMessage}</div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="row">
    <div className="col-lg-4 col-xl-3 d-none d-lg-block">
      <div className="sticky-top">
        <ViewProgressNav />
      </div>
    </div>
    <div className="col-12 col-lg-8 col-xl-9">
      {(() => {
        switch (currentStep) {
          case Step.SelectSession:
            return ViewSelectSessionPage();
          case Step.SelectTime:
            return ViewSelectTimePage();
          case Step.ConfirmPurchase:
            return ViewConfirmPurchasePage();
          default:
            return null;
        }
      })() as React.ReactNode}
    </div>
  </div>
  );
}

export default SessionCheckout;
