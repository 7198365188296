import * as React from 'react';
import { useState } from 'react';
import * as API from '../../Utils/API.js';
import { EssayReview, ReviewStatus } from '../../Types/Essays';
import { formatMultiParagraphString, htmlIf, maybeHtml } from '../../Utils/HTML';
import { AdvisorEssayStatusBadge } from '../../Common/StatusBadges';
import { formatUSDWithCents } from '../../Utils/Currency';
import { DismissibleModal } from '../../Components/Modal';
import { CustomerRating } from '../../Types/CustomerRating';
import { ReadOnlyRating } from '../../Common/Ratings';

type Props =
  { review: EssayReview
  , googleDriveUrl?: string
  }

enum Modal
  { NoModal
  , ConfirmSubmit
  }

const advisorEssaysPath = '/advisor/essays'

function messagesUrl(userId: number) {
  return `/messages?other_user_id=${userId}`
}

function formatDate(dateString: string):string {
  const date = new Date(dateString)
  return (
    date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
  )
}

const ReviewDetails = (props: Props) => {
  const [review, setReview] = useState(props.review);
  const [advisorFeedback, setAdvisorFeedback] = useState('')
  const [modal, setModal] = useState(Modal.NoModal);
  const [showErrors, setShowErrors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function submitReview() {
    setIsSubmitting(true);
    const body = { essayReviewId: review.id, advisorFeedback: advisorFeedback }
    API.post("advisor_essays_submit_review_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setReview(result['review'])
        setModal(Modal.NoModal)
        setIsSubmitting(false);
      }
    })
  }

  const ViewModal = () => {
    switch(modal) {
      case Modal.NoModal:
        return null
      case Modal.ConfirmSubmit:
        return (
          <DismissibleModal
            title={<h4>Submit this review?</h4>}
            body={
              <div>
                Are you sure you want to submit this review? No further edits can be made to your feedback or via the Google Doc.
                <div className="mt-2">
                  The customer will receive an email notification letting them know their essay has been reviewed.
                </div>
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-secondary w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  No, not quite yet
                </button>
                <button className="btn btn-primary w-100 w-sm-auto ms-sm-2 t--confirm-cancel-request"
                  onClick={() => submitReview()}
                  disabled={isSubmitting}
                >
                  Yes, submit it
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
    }
  }

  const ViewHeader = () => (
    <div className="p-3 border-bottom">
      <div className="">
        <div className="row align-items-center">
          <div className="col-lg d-lg-flex align-items-center">
            <h3 className="mb-0 me-1">#{review.id} ({review.packageName})</h3>
            <AdvisorEssayStatusBadge review={review}/>
          </div>
          <div className="col-auto d-flex justify-content-end mt-1 mt-lg-0">
            <a className="btn btn-outline-primary px-3 t--view-messages" href={messagesUrl(review.customerUserId)}>
              Messages
            </a>
          </div>
        </div>
        <div className="mt-1">
          <div className="fs-sm">
            {htmlIf(!!review.paidAt,
              <>
                Purchased
                <span className=""> {formatDate(review.paidAt)}</span>
              </>
            )}
            {htmlIf(review.status === ReviewStatus.COMPLETED && !!review.completedAt,
              <>
                <span className="mx-1">•</span>
                Returned
                <span className=""> {formatDate(review.completedAt)}</span>
              </>
            )}
            {htmlIf(review.status === ReviewStatus.REFUNDED && !!review.refundedAt,
              <>
                <span className="mx-1">•</span>
                Refunded
                <span className=""> {formatDate(review.refundedAt)}</span>
              </>
            )}
          </div>
        </div>
        {htmlIf(review.guaranteedReturnTimeHours > 0,
          <div className="mt-1 fw-semibold">
            Guaranteed Return Time: {review.guaranteedReturnTimeHours} hours
          </div>
        )}
      </div>
    </div>
  )

  const ViewOverview = () => {
    return (
      <div className="p-3 border-bottom">
        <div className="fs-lg text-dark">Submission Details</div>
        <div className="row mt-1">
          <div className="col-md-6 d-flex align-items-center">
            Submitted by:
            <div className="fw-semibold ms-1">{review.customerName}</div>
          </div>
          <div className="col-md-6 mt-2 mt-md-0 d-flex align-items-center">
            Amount paid:
            <div className="fw-semibold ms-1">{formatUSDWithCents(review.amountCents)}</div>
          </div>
        </div>
        <div className="h6 mb-0 mt-3">Essay Prompt</div>
        <div className="">{review.essayPrompt}</div>
        <div className="h6 mb-0 mt-3">Word Limit</div>
        <div className="">{review.essayWordLimit}</div>
        <div className="h6 mb-0 mt-3">Which schools is this esssay for?</div>
        <div className="">{review.essayColleges.join(' | ')}</div>
        <div className="h6 mb-0 mt-3">What are you looking for feedback on?</div>
        <div className="">{review.essayFeedbackRequest}</div>
      </div>
    )
  }

  const ViewGoogleDriveLink = () => {
    return (
      <div className="p-3 border-bottom">
        <div className="fs-lg text-dark">Ready to start your review? Read the essay and leave in-line edits, comments, and suggestions via a Google Doc.</div>
        <a href={props.googleDriveUrl} target='_blank' className="btn btn-info mt-2">
          <i className="ai-external-link me-1" />
          Open Google Doc
        </a>
      </div>
    )
  }

  const ViewCompletedEssayDetails = () => {
    return (
      <div className="p-3">
        {maybeHtml(review.essayDocxUrl, (docUrl) => (
          <div className="mb-3">
            <div className="fs-lg text-dark">Download the completed review here:</div>
            <a href={docUrl} target='_blank' className="btn btn-info mt-2">
              <i className="ai-external-link me-1" />
              Download essay
            </a>
          </div>
        ))}
        <div className="fs-lg text-dark">Overall feedback:</div>
        <div className="fs-md">{formatMultiParagraphString(review.advisorFeedback)}</div>
      </div>
    )
  }

  const ViewFeedbackBox = () => {
    function handleSubmitReviewClicked() {
      setShowErrors(false);

      if (advisorFeedback.length > 150) {
        setModal(Modal.ConfirmSubmit);
      } else {
        setShowErrors(true);
      }
    }

    return (
      <div className="p-3">
        <div className="fs-lg text-dark">Once you’re finished, come back here to leave overall feedback on the submission.</div>
        <div className="fs-sm mt-1">Be sure to address the specific feedback requested by the student and offer actionable next steps. This is a great opportunity to upsell (more essay reviews, a paid session, or a package) at the end of your message!</div>
        <textarea
          className="form-control mt-2 t--advisor-feedback"
          rows={8}
          placeholder="Add your feedback…"
          value={advisorFeedback}
          onChange={(event) => setAdvisorFeedback(event.target.value)}
        />
        {htmlIf(showErrors && advisorFeedback.length < 150,
          <div className="fs-sm mt-1 text-danger fw-bold">Please provide additional feedback.</div>
        )}
        <div className="mt-3 text-end">
          <button className="btn btn-primary" onClick={() => handleSubmitReviewClicked()}>Submit review</button>
        </div>
      </div>
    )
  }

  const ViewRating = (customerRating: CustomerRating) => (
    <div className="p-3 border-top">
      <h5 className="mb-0">Rating and feedback</h5>
      <div className="row mt-2">
        <div className="col-6 col-md-3">
          <ReadOnlyRating rating={customerRating.rating} starClass={'h3 mb-0 fw-bold text-warning'}/>
        </div>
      </div>
      {maybeHtml(customerRating.ratingComment, (publicFeedback) => (
        <div className="mt-3">
          <div className="fs-md fw-semibold text-gray-900">Public rating comment</div>
          <div className="fs-md">{publicFeedback}</div>
        </div>
      ))}
      {maybeHtml(customerRating.advisorFeedback, (advisorFeedback) => (
        <div className="mt-3">
          <div className="fs-md fw-semibold text-gray-900">Private feedback</div>
          <div className="fs-md">{advisorFeedback}</div>
        </div>
      ))}
    </div>
  )

  return (
    <>
      {ViewModal()}
      <div className="row align-items-end">
        <div className="col-auto d-flex align-items-center">
          <a href={advisorEssaysPath} className="text-decoration-none align-items-center d-flex">
            <i className="ai-arrow-left me-1"/>
            <i className="ai-grid me-1"/>
            All essays
          </a>
        </div>
      </div>
      <div className="card rounded-2 mt-1">
        {ViewHeader()}
        {ViewOverview()}
        {htmlIf(review.status === ReviewStatus.AWAITING_PAYMENT,
          <div className="p-3 fs-lg">
            This review has not yet been paid for — check back later!
          </div>
        )}
        {htmlIf(review.status === ReviewStatus.IN_PROGRESS && props.googleDriveUrl,
          ViewGoogleDriveLink()
        )}
        {htmlIf(review.status === ReviewStatus.IN_PROGRESS,
          ViewFeedbackBox()
        )}
        {htmlIf(review.status === ReviewStatus.COMPLETED,
          ViewCompletedEssayDetails()
        )}
        {htmlIf(review.status === ReviewStatus.REFUNDED,
          <div className="p-3 fs-lg">
            This review has been refunded and review details are no longer available.
          </div>
        )}
        {maybeHtml(review.customerRating, (rating) => (
          ViewRating(rating)
        ))}
      </div>
    </>
  )
}

export default ReviewDetails;
