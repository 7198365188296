import * as React from 'react';
import { useState, useEffect } from 'react';
import { EssayReviewCard, EssayReviewCardProps } from './ConsultantCard';

type Props =
  { cardData: EssayReviewCardProps[]
  , targetBlank?: boolean
  }

const EssayReviewGrid = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const [filteredAdvisors, setFilteredAdvisors] = useState(props.cardData);

  // This hook serves to re-sort and re-filter the list of events when the search text is changed
  useEffect(() => {
    const filtered = props.cardData.filter((card) => {
      const searchFields = [
        card.advisor.preferredName,
        card.advisor.location
      ];
      return (
        searchFields.some((field) =>
          field?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });

    setFilteredAdvisors(filtered);
  }, [searchText])

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchText(query);
  }

  return (
    <>
      {/* <div className="row mt-3 align-items-center">
        <div className="col-md-8">
          <div className="input-group align-items-center p-0 bg-nav-background">
            <div className="input-group-prepend ms-2">
              <i className="ai-search"></i>
            </div>
            <input
              className="form-control"
              type="search"
              value={searchText}
              onChange={handleSearchInputChange}
              placeholder="Search advisors…"
            />
          </div>
        </div>
      </div> */}
      <div className="row mt-3">
        {filteredAdvisors.map((card) => (
          <div className="col-md-6 col-lg-4 mb-2">
              <EssayReviewCard
                advisor={card.advisor}
                targetBlank={props.targetBlank}
                returnTimeTiers={card.returnTimeTiers}
                reviewStartingAtCents={card.reviewStartingAtCents}
              />
          </div>
        ))}
      </div>
    </>
  )
}

export default EssayReviewGrid;
