/**
  * Takes a number of cents and formats it into USD (with cents displayed).
  * @param {number} cents - The amount (in cents) to display as USD.
  * @return {string} - The USD value (for example: $32.65)
*/
export function formatUSDWithCents(cents: number): string {
  return Number(cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

/**
 * Takes a number of cents and formats it into USD (without cents displayed).
 * @param {number} cents - The amount (in cents) to display as USD.
 * @return {string} - The USD value (for example: $32)
 */
export function formatUSDWithoutCents(cents: number): string {
  return Number(cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
}
