import * as React from 'react';
import Calendar from 'react-calendar';
import { maybeHtml } from '../Utils/HTML';
import { getLocalTimeZoneName } from '../Utils/DateTime';

type Props =
  { layout: Layout
  , availableDateTimes: string[]
  , selectedDate?: Date
  , selectedTime?: Date
  , onSelectDate: (date: Date) => void
  , onSelectTime: (time: Date) => void
  }

export enum Layout
  { Narrow
  , Wide
  }

function formatDate(date: Date) {
  return (
    date.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric'
    })
  )
}

function formatTime(time: Date) {
  return (
    time.toLocaleDateString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    })
  )
}

const Schedule = (props: Props) => {
  const enabledDates: Date[] = props.availableDateTimes.map(dateString => new Date(dateString));

  const ViewCalendar = () => (
    <Calendar
      tileDisabled={({ date, view }) => {
        if (enabledDates === null || view !== "month") {
          return false
        }

        return !enabledDates.find((d) => {
          const yearEq = d.getFullYear() === date.getFullYear()
          const monthEq = d.getMonth() === date.getMonth()
          const dayEq = d.getDate() == date.getDate()

          return yearEq && monthEq && dayEq
        })
      }

      }
      value={props.selectedDate ? (props.selectedDate) : null}
      onChange={(value, event) => {
        props.onSelectDate(value as Date)
      }}
      navigationLabel={({ date }) => {
        // Render the month and year, but do not make it clickable
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        return <span>{`${month} ${year}`}</span>;
      }}
      showNeighboringMonth={false}
      next2Label={null}
      prev2Label={null}
    />
  )

  const ViewTimeSelectors = (selectedDate: Date) => {
    const ViewTime = (time: string) => (
      <button className={`btn w-100  py-1 px-3 mb-1 t--select-time ${props.selectedTime?.getTime() === new Date(time).getTime() ? 'btn-primary' : 'btn-outline-secondary'}`}
        onClick={() => props.onSelectTime(new Date(time))}
      >
        {formatTime(new Date(time))}
      </button>
    )

    return (
      <>
        <div className="fw-bold">{formatDate(selectedDate)}</div>
        <div className="text-secondary mb-3">All times are shown in your local timezone ({getLocalTimeZoneName()})</div>
        {props.availableDateTimes.filter((dateTime) => (
          new Date(dateTime).getDate() === selectedDate.getDate() &&
          new Date(dateTime).getMonth() === selectedDate.getMonth() &&
          new Date(dateTime).getFullYear() === selectedDate.getFullYear()
        )).map((time) => (
          ViewTime(time)
        ))}
      </>
    )
  }

  return (
    <div>
      { props.layout === Layout.Narrow
      ? <>
          <ViewCalendar />
          {maybeHtml(props.selectedDate, (selDate) => (
            <div className="mt-3">
              {ViewTimeSelectors(selDate)}
            </div>
          ))}
        </>
      : <div className="row">
          <div className="col-lg-8 col-xl-7">
            <ViewCalendar />
          </div>
          <div className="col-lg-4 col-xl-5 mt-3 mt-lg-0">
            {maybeHtml(props.selectedDate, (selDate) => (
              ViewTimeSelectors(selDate)
            ))}
          </div>
        </div>
      }
    </div>
  )
}

export default Schedule;
