import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import * as API from '../Utils/API.js';
import { updateWhere, modifyWhere } from '../Utils/Array';
import { htmlIf, formatMultiParagraphString, maybeHtml } from '../Utils/HTML';
import { lastSeenAtDescription, timeAgo } from '../Utils/DateTime';
import { Tooltip } from 'react-tooltip'

type Props =
  { conversations: Conversation[]
  , currentUser: User
  // We're currently testing whether or not to format messages with markddown (using ReactMarkdown). This
  // is passed via a feature flag.
  , formatWithMarkdown: boolean
  // Sometimes, we'll want to have the page load and immediately jump to a particular message thread. We can do that
  // by setting the presetConversationId property. Conversation IDs can be generated by the `DirectMessageService` in Ruby.
  , presetConversationId?: string
  }

type Conversation =
  { messages: Message[]
  , otherParty: User
  , conversationId: string
  }

type Message =
  { id: number
  , body: string
  , attachment?:
    { name: string
    , url: string
    }
  , fromUserId: number
  , toUserId: number
  , isSystemMessage: boolean
  , readAt?: string
  , sentAt: string
  }

type User =
  { name: string
  , userId: number
  , avatarUrl: string
  , lastSeenAt: string
  , advisorProfilePath?: string
  }

const consultantsPath = '/consultants';

const Messages = (props: Props) => {
  const [conversationList, setConversationList] = useState(props.conversations);
  const [selectedConversation, setSelectedConversation] = useState<Conversation>(
    props.conversations.find(conversation => conversation.conversationId === props.presetConversationId) ??
    props.conversations[0] ?? null
  );
  const [otherUserIsTyping, setOtherUserIsTyping] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [filteredConversations, setFilteredConversations] = useState(props.conversations);

  const [newMessageFiles, setNewMessageFiles] = useState<File[]>([]);

  // When storing newMessageBody as a state variable, the full messages component must re-render every time a
  // key is pressed in the new message text area, which badly slows down the messaging experience.
  // Instead, we use a ref (newMessageBodyRef) to access the text area, and only update state (via isNewMessageBodyTextPresent)
  // when the text area input changes from blank to not blank (when blank, we disable the "Send" button. When not blank, we enable it).
  const [isNewMessageBodyTextPresent, setIsNewMessageBodyTextPresent] = useState(false);
  const newMessageBodyRef = useRef(null);

  const messagesEndRef = useRef(null)
  const selectedConversationRef = useRef(selectedConversation);
  const typingIndicatorSubscription = useRef<ActionCable.Channel>();

  // Subscribe to the MessagesChannel, which will receive a broadcast any time a new message is sent to the current user.
  // This broadcast is managed in the Messages::Controller in Ruby.
  useEffect(() => {
    const messagesSubscription = App.cable.subscriptions.create("Messages::NewMessageChannel", {
      received: (updatedConversationList) => {
        setConversationList(updatedConversationList);
        const currentSelectedConversation = selectedConversationRef.current;
        if (currentSelectedConversation !== null && currentSelectedConversation !== undefined) {
          setSelectedConversation(
            updatedConversationList.find((conversation) => conversation.conversationId === currentSelectedConversation.conversationId)
          );
        }
      }
    });

    // Unsubscribe when the component unmounts.
    return () => {
      messagesSubscription.unsubscribe();
    };
  }, [])

  // Auto-scroll to the bottom of the conversation thread any time a new conversation is selected.
  useEffect(() => {
    scrollMessagesToBottom();
  }, [selectedConversation, conversationList])

  function scrollMessagesToBottom() {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto", block: "nearest", inline: "nearest"})
  }

  // Subscribe to the Messages::TypingIndicatorChannel, which will receive a broadcast when another user is typing
  // in the same "room" (where the "room" is identified by the conversation ID as defined in the DirectMessageService).
  // Since typing is a client-side event, this broadcast is managed right here in this component — see the ViewConversation()
  // function for more context.
  useEffect(() => {
    selectedConversationRef.current = selectedConversation;
    setOtherUserIsTyping(false);

    typingIndicatorSubscription.current = App.cable.subscriptions.create(
      { channel: "Messages::TypingIndicatorChannel", conversationId: selectedConversation?.conversationId },
      {
        received(data) {
          console.log(data);
          if (data.typingUserId !== props.currentUser.userId) {
            setOtherUserIsTyping(data.isTyping);
          }
        }
      }
    );

    // Unsubscribe when another conversation is selected.
    return () => {
      typingIndicatorSubscription.current.unsubscribe();
    };
  }, [selectedConversation]);

  // Whenever the search text is changed (or the conversation list is updated), re-filter the conversation list.
  useEffect(() => {
    const filtered = conversationList.filter((conversation) => {
      const searchFields = [
        conversation.otherParty.name
      ];
      return (
        searchFields.some((field) =>
          field?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });

    setFilteredConversations(filtered);
  }, [conversationList, searchText])

  // Courtesy of ChatGPT, triggers sendMessage() when Command + Enter / Ctrl + Enter are pushed.
  const handleSendMessageKeysDown = useCallback((event) => {
    // For Mac (Command + Enter) and Windows/Linux (Ctrl + Enter)
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault(); // Prevent default Enter behavior

      // Ensure the same conditions as the button's disabled state
      if (isNewMessageBodyTextPresent || newMessageFiles.length > 0) {
        sendMessage();
      }
    }
  }, [isNewMessageBodyTextPresent, newMessageFiles]); // Track changes to both states

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      document.addEventListener('keydown', handleSendMessageKeysDown);

      return () => {
        document.removeEventListener('keydown', handleSendMessageKeysDown);
      };
    }
    return () => {};
  }, [handleSendMessageKeysDown]);

  function sendMessage() {
    let currentConversation = selectedConversation
    let formData = new FormData();
    formData.append("body", newMessageBodyRef.current.value);
    for (var i = 0; i < newMessageFiles.length; i++) {
      formData.append("files[]", newMessageFiles[i]);
    }
    formData.append("fromUserId", props.currentUser.userId.toString());
    formData.append("toUserId", currentConversation.otherParty.userId.toString());
    formData.append("conversationId", currentConversation.conversationId);
    newMessageBodyRef.current.value = "";
    setIsNewMessageBodyTextPresent(false);
    setNewMessageFiles([]);
    API.postFormData('messages_send_message_path', formData).then(function (result) {
      const updatedConversationList = result
      setConversationList(updatedConversationList);
      const currentSelectedConversation = selectedConversationRef.current;
        if (currentSelectedConversation !== null && currentSelectedConversation !== undefined) {
          didSelectConversation(
            updatedConversationList.find((conversation) => conversation.conversationId === currentSelectedConversation.conversationId)
          );
        }
    })
  }

  function markRead(conversation: Conversation) {
    const postBody = { conversationId: conversation.conversationId }

    API.post('messages_mark_read_path', postBody).then(function () {
      setConversationList((conversationList) =>
        modifyWhere(
          (c: Conversation) => c.conversationId === conversation.conversationId,
          (c: Conversation) => ({...c, messages: modifyWhere(
            (m: Message) => m.readAt === null,
            (m: Message) => ({...m, readAt: new Date().toString()}),
            c.messages
          )}),
          conversationList
        )
      )
    })
  }

  function didSelectConversation(conversation: Conversation) {
    setSelectedConversation(conversation);
    markRead(conversation);
  }

  function numUnreadMessages(conversation: Conversation): number {
    return conversation.messages.filter((message) =>
      (!message.readAt && message.fromUserId != props.currentUser.userId)).length
  }

  const ViewConversationTitleCard = ({ conversation }: { conversation: Conversation }) => {
    const isActive = selectedConversation?.conversationId === conversation.conversationId
    return (
      <div
        className={`d-flex align-items-center rounded-1 text-decoration-none p-2 hover-gray-100 border-bottom t--conversation-card ${isActive ? "bg-gray" : ""}`}
        style={{cursor: 'pointer'}}
        onClick={() => didSelectConversation(conversation)}
        data-bs-dismiss='offcanvas'
        data-bs-target='#contactsList'
        >
        <div className="flex-shrink-0 my-1 avatar avatar-sm">
          <img src={conversation.otherParty.avatarUrl} className='avatar-img rounded-circle'/>
        </div>
        <div className="overflow-hidden w-100">
          <div className="d-flex justify-content-between align-items-center w-100 px-1 ms-1 my-1">
            <div className="me-2">
              <div className="fs-md fw-bold text-dark">{conversation.otherParty.name}</div>
            </div>
            <div className="text-end">
              <span className="d-block fs-xs text-muted">
                {timeAgo(new Date(conversation.messages[conversation.messages.length -1].sentAt))}
              </span>
            </div>
          </div>
          <div className="ps-1 ms-1 mt-1 fs-sm truncate">
            {conversation.messages[conversation.messages.length - 1].body}
          </div>
        </div>
        {htmlIf(numUnreadMessages(conversation) > 0,
          <span className="badge bg-danger fs-xs lh-1 py-1 px-1 ms-1">{numUnreadMessages(conversation)}</span>
        )}
      </div>
    )
  }

  /**
   * Based on the provided message index and message list, determines if a date separator (a line across the
   * conversation with the date, indicating that previous messages are from a prior day and following messages
   * are from the listed day) is required, and, if so, renders the date separator.
   * @param index - The index of the current message (the one following the potential date separator)
   * @param messages — The full list of messages
   * @returns A React component that will render the date separator, if appropriate.
   */
  const RenderDateSeparator = ({ messageIndex, messages }: { messageIndex: number, messages: Message[] }) => {
    let shouldShowSeparator = false

    if (messageIndex === 0) {
      shouldShowSeparator = true; // This is the first message, so we should show a date separator
    } else {
      const prevMessageDate = new Date(messages[messageIndex - 1].sentAt).toLocaleDateString();
      const currentMessageDate = new Date(messages[messageIndex].sentAt).toLocaleDateString();

      if (prevMessageDate !== currentMessageDate) {
        shouldShowSeparator = true; // The previous message was sent on a different date than the current message, so we should show a date separator
      }
    }

    return (
      htmlIf(shouldShowSeparator,
        <div className="text-surrounded-by-line fs-sm text-gray-700 my-3">
          {new Date(messages[messageIndex].sentAt).toLocaleDateString("en-US", { weekday: "long", day: "numeric", month: "long", year: "numeric" })}
        </div>
      )
    )
  }

  /**
    Renders a message in the conversation view.
    @param message - The message to render.
    @param endOfThought - A boolean indicating whether this message is the end of a "thought." We do
      this in order to group messages together that were sent within a few minutes of one another (much
      like how iMessage does). If `endOfThought` is true, we add a larger bottom margin to the message and
      display the time the message was sent below the message. If `endOfThought` is false, we remove the bottom
      margin and hide the time the message was sent at.
    @returns A React component representing the message.
  */
  const ViewMessage = ({ message, startOfThought, endOfThought }: { message: Message, startOfThought: boolean, endOfThought: boolean }) => {
    const fromCurrentUser = message.fromUserId === props.currentUser.userId

    function messageText() {
      return (
        <div>
          {formatMultiParagraphString(message.body)}
          {message.attachment ? (
            <div className='my-1 text-primary'>
              <a href={message.attachment.url} target='_blank' className='d-flex align-items-center text-decoration-none'>
                <i className="ai-paperclip me-1" />
                <div className="text-hover-underline">{message.attachment.name}</div>
              </a>
            </div>
          ) : null
          }
        </div>
      )
    }

    function formatDate(dateString: string):string {
      const date = new Date(dateString)
      const timeString = date.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true, timeZoneName: "short" });
      return `${timeString}`;
    }

    if (message.isSystemMessage) {
      return (
        <div className="row g-0 no-gutters align-items-start my-3">
          <div className="fs-xs text-muted">{messageText()}</div>
        </div>
      )
    }
    else {
      return (
        <div className={`row g-0 no-gutters ${startOfThought ? 'mt-3 align-items-start' : 'align-items-center'}`}>
          <div className="col-auto">
            <div className='flex-shrink-0 avatar avatar-xs'>
              <img src={fromCurrentUser ? props.currentUser.avatarUrl : selectedConversation.otherParty.avatarUrl}
                className={startOfThought ? 'avatar-img rounded-circle' : 'd-none'}
              />
            </div>
          </div>
          <div className="ms-1 col w-75">
            <div className={startOfThought ? "fs-md fw-semibold" : "d-none"} suppressHydrationWarning>
              {fromCurrentUser ? props.currentUser.name : selectedConversation.otherParty.name}
            </div>
            <div className="fs-md">
              {messageText()}
            </div>
            <div className={endOfThought ? "fs-xs text-muted" : "d-none"} suppressHydrationWarning>
              {formatDate(message.sentAt)}
            </div>
          </div>
        </div>
      )
    }
  }

  const ViewConversation = (conversation: Conversation) => {
    // This defines the maximum allowable time between two messages for them to be considered part of the same "thought."
    const numSecondsBetweenMessages = 120

    /**
     * Determines whether a particular message is the end of a "thought" — for the last message of a thought,
     * we display the time the message was sent.
     *
     * @param messageIndex - The index of the current message in the list of messages.
     * @param messages - The list of messages.
     * @returns A boolean indicating whether the message is the end of a "thought"
     */
    function shouldEndThought(messageIndex: number, messages: Message[]): boolean {
      if (messageIndex === messages.length - 1) {
        return true; // This is the last message, so we should end the thought
      }

      const message = messages[messageIndex];
      const nextMessage = messages[messageIndex + 1];

      if (new Date(nextMessage.sentAt).getTime() - new Date(message.sentAt).getTime() > (1000 * numSecondsBetweenMessages)) {
        return true; // The next message was sent more than the alloted number of seconds after this one, so we should end the thought
      }

      if (nextMessage.fromUserId !== message.fromUserId) {
        return true; // The next message was sent by a different user, so we should end the thought
      }

      return false;
    }

    /**
     * Determines whether a particular message is the start of a "thought" — for the first message of a thought,
     * we display the sender's avatar and name.
     *
     * @param messageIndex - The index of the current message in the list of messages.
     * @param messages - The list of messages.
     * @returns A boolean indicating whether the message is the start of a "thought"
     */
    function shouldStartThought(messageIndex: number, messages: Message[]): boolean {
      if (messageIndex === 0) {
        return true; // This is the first message, so we should start the thought
      }

      const prevMessage = messages[messageIndex - 1];
      const message = messages[messageIndex];

      if (prevMessage.isSystemMessage) {
        return true;
      }

      if (shouldEndThought(messageIndex - 1, messages)) {
        return true; // If the previous message was the end of a thought, then the current message is the start of a thought.
      }

      return false;
    }

    function onTypingStart() {
      typingIndicatorSubscription.current.perform("typing", {
        conversationId: conversation.conversationId,
        typingUserId: props.currentUser.userId,
        isTyping: true
      })
    }

    function onTypingEnd() {
      typingIndicatorSubscription.current.perform("typing", {
        conversationId: conversation.conversationId,
        typingUserId: props.currentUser.userId,
        isTyping: false
      })
    }

    function textAreaOnChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
      if (event.target.value === "") {
        setIsNewMessageBodyTextPresent(false)
      } else {
        isNewMessageBodyTextPresent ? null : setIsNewMessageBodyTextPresent(true)
      }

      if (parseInt(newMessageBodyRef.current.scrollHeight) >= 192) {
        return;
      } else {
        newMessageBodyRef.current.style.height = 'auto';
        newMessageBodyRef.current.style.height = `${newMessageBodyRef.current.scrollHeight}px`;
        scrollMessagesToBottom();
      }
    }

    return (
      <div className="card rounded-2 h-100" style={{'maxHeight': '700px'}}>
        <div className="navbar card-header w-100 mx-0 p-2">
          <div className="d-flex align-items-center w-100 px-sm-2">
            <button
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#contactsList"
              aria-controls="contactsList"
              className="navbar-toggler d-lg-none me-2 me-sm-3 py-1">
              <span className="ai-chevron-left fs-xxl"></span>
            </button>
            <div className="d-flex align-items-center">
              <h5 className="h5 mb-0">{conversation.otherParty.name}</h5>
              <div className="ms-1 fs-xs">{lastSeenAtDescription(conversation.otherParty.lastSeenAt)}</div>
            </div>
            {maybeHtml(conversation.otherParty.advisorProfilePath, (profileUrl) => (
              <a href={profileUrl} target="_blank" className="ms-auto btn btn-sm btn-link p-0">
                <i className="ai-external-link pe-1"/>
                View Profile
              </a>
            ))}

          </div>
        </div>
        <div className="card-body px-4 pt-2 pb-0 overflow-auto">
          {conversation.messages.map((message, index, messages) => (
            <>
              <RenderDateSeparator
                messageIndex={index}
                messages={messages}
                key={`date-separator-${message.id}`}
              />
              <ViewMessage
                message={message}
                endOfThought={shouldEndThought(index, messages)}
                startOfThought={shouldStartThought(index, messages)}
                key={message.id}
              />
            </>
          ))}
          <div className="mt-2" ref={messagesEndRef}/>
        </div>
        {htmlIf(otherUserIsTyping,
            <div className="card-body py-0 text-secondary fs-sm">{conversation.otherParty.name} is typing…</div>
        )}
        <div className="d-flex px-2 align-items-center border-top">
          <div className="p-1 pe-2">
            <label htmlFor="messageAttachment" className=""
              data-tooltip-id={"attachment"}
              data-tooltip-place="top"
              data-tooltip-position-strategy='fixed'
              data-tooltip-content='Attach a file'
            >
              <i className="ai-paperclip fs-xxl text-secondary cursor-pointer"/>
            </label>
            <input
              className="d-none" type="file" multiple id="messageAttachment"
              onChange={(event) => setNewMessageFiles(Array.from(event.target.files))}
            />
            <Tooltip id='attachment'/>
          </div>
          <textarea className="form-control px-1 py-1 rounded-0 border-0 border-start d-flex align-items-center t--new-message-body"
            placeholder='Type a message…' style={{resize: 'none', lineHeight: '1.5'}} id="messageInput"
            onChange={textAreaOnChange}
            rows={2}
            ref={newMessageBodyRef}
            onFocus={onTypingStart}
            onBlur={onTypingEnd}
          />
          <button className="btn btn-link p-0 t--send-message fs-4"
            onClick={sendMessage}
            type='button'
            disabled={!isNewMessageBodyTextPresent && (newMessageFiles.length === 0)}
            data-tooltip-id={"sendMessage"}
            data-tooltip-place="top"
            data-tooltip-position-strategy='fixed'
            data-tooltip-content={
              /Mac/.test(navigator.userAgent)
                ? "Command + Enter to send"
                : /Win|Linux/.test(navigator.userAgent)
                  ? "Ctrl + Enter to send"
                  : "" // No tooltip for iOS (or other devices without a keyboard)
            }
          > <i className="ai-send" />
          </button>
          <Tooltip id='sendMessage'/>
        </div>
        <div className={newMessageFiles.length > 0 ? "px-3 pb-1 fs-sm" : 'd-none'}>
          {newMessageFiles.map((file) => (
            <div>
              Attached: <b>{file?.name?.replace(/.*[\/\\]/, '')}</b>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const ViewEmptyConversation = () => {
    return (
      <div className="card h-100">
        <div className='d-lg-none navbar card-header w-100 mx-0 px-3 py-1'>
          <button
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#contactsList"
            aria-controls="contactsList"
            className="navbar-toggler me-2 me-sm-3">
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="card-body text-center d-flex justify-content-center align-items-center">
          <h3 className="m-0">Select a conversation to get started!</h3>
        </div>
      </div>
    )
  }

  const ViewEmptyState = () => {
    return (
      <div className="my-3 text-center">
        <div className="avatar avatar-md">
          <div className="avatar-title bg-accent rounded text-dark h3">
            <i className="ai-file-text" />
          </div>
        </div>
        <div className="fs-lg text-dark mt-2">You don't have any messages — find an advisor to get started!</div>
        <a href={consultantsPath} className="btn btn-primary mt-2">Find an advisor</a>
      </div>
    )
  }

  return (
    <div>
      { conversationList.length > 0
      ?
        <div className="rounded p-0 px-lg-3 pt-0">
          <div className="row position-relative overflow-hidden gx-2 zindex-1">
            <div className="col-lg-4">
              <div
                className="offcanvas-lg offcanvas-start position-absolute position-lg-relative h-100 bg-light rounded-2 border"
                data-bs-scroll='true'
                data-bs-backdrop='false'
                style={{'height':'100vh'}}
                id="contactsList">
                <div className="rounded-0 overflow-hidden">
                  <div className="card-header w-100 border-bottom p-2">
                    <div className="d-flex d-lg-none justify-content-between align-items-center">
                      <h5 className="mb-0">My Messages</h5>
                      <button
                        type='button'
                        data-bs-dismiss='offcanvas'
                        data-bs-target='#contactsList'
                        className="btn btn-outline-secondary border-0 px-2 me-n1">
                          <i className="ai-cross me-1"/>
                      </button>
                    </div>
                    <h5 className="mb-0 d-none d-lg-block">My Messages</h5>
                  </div>
                  <div className="card-body px-2 pb-2 overflow-auto" style={{'maxHeight': '700px'}}>
                    <div className="input-group align-items-center p-0 my-1 bg-white">
                      <div className="input-group-prepend ms-2">
                        <i className="ai-search"></i>
                      </div>
                      <input
                        className="form-control"
                        type="search"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        placeholder="Search by name…"
                      />
                    </div>
                    {filteredConversations.map((conversation, index) => (
                      <ViewConversationTitleCard
                        conversation={conversation}
                        key={conversation?.conversationId}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              { selectedConversation && conversationList.length > 0
                ? ViewConversation(selectedConversation)
                : conversationList.length > 0
                  ? <ViewEmptyConversation />
                  : <ViewEmptyState />
              }
            </div>
          </div>
        </div>
      :
        <div className="card border-0 card-body">
          <ViewEmptyState />
        </div>
      }
    </div>
  )
};


export default Messages;
