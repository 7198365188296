import * as React from 'react';
import { useState, useEffect, Dispatch } from 'react';
import * as API from '../Utils/API.js';
import { EssayReview } from '../Types/Essays';
import { htmlIf, maybeHtml } from '../Utils/HTML';
import { DismissibleModal } from '../Components/Modal';
import { CustomerRating } from '../Types/CustomerRating';

type RatingModalButtonProps = {
  purchaseType: PurchaseType,
  purchaseId: number,
  advisorName: string,
  presetOverallRating?: number,
  buttonClass: string,
  buttonText: string,
  purchaseSetter: Dispatch<React.SetStateAction<EssayReview>>
}

export enum PurchaseType {
  ESSAY_REVIEW = 'Essays::Review'
}

export const RatingModalButton = (props: RatingModalButtonProps) => {
  const [showModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [overallRating, setOverallRating] = useState(props.presetOverallRating);
  const [ratingComment, setRatingComment] = useState('');
  const [advisorFeedback, setAdvisorFeedback] = useState('');
  const [sundialFeedback, setSundialFeedback] = useState('');

  useEffect(() => {
    setOverallRating(props.presetOverallRating)
  }, [showModal])

  function ratePurchase() {
    setIsSaving(true);
    const body =
      { purchaseType: props.purchaseType
      , purchaseId: props.purchaseId
      , rating: overallRating
      , ratingComment: ratingComment
      , advisorFeedback: advisorFeedback
      , sundialFeedback: sundialFeedback
      }
    switch (props.purchaseType) {
      case PurchaseType.ESSAY_REVIEW:
        API.post("essays_rate_review_path", body).then(function (result) {
          if (result['error']) {
          } else {
            props.purchaseSetter(result['review'])
            setShowModal(false);
          }
          setIsSaving(false);
        })
    }
  }

  return (
    <>
      <button className={props.buttonClass} onClick={() => setShowModal(true)}>{props.buttonText}</button>
      {htmlIf(showModal,
        <DismissibleModal
          dialogClass='modal-lg'
          title={<div className="fs-xl fw-bold text-gray-900">Add a rating for {props.advisorName}</div>}
          body={
            <>
              <div className="mt-3">
                <div className="fs-md fw-semibold text-gray-900 mb-1">Rating</div>
                <FiveStarRating rating={overallRating} setRating={setOverallRating} starClass='h2 mb-0 text-warning pe-1'/>
              </div>
              <div className="mt-3">
                <div className="fs-md text-gray-900 mb-1">
                  <span className="fw-semibold">Public Feedback</span>
                  &nbsp;(recommended)
                </div>
                <textarea
                  className="form-control t--rating-comment"
                  rows={4}
                  placeholder='Please share your feedback on this Advisor — this feedback will be visible on their profile.'
                  onChange={(e) => setRatingComment(e.target.value) }
                  value={ratingComment || ""}
                />
                <div className="fs-md text-gray-900 mb-1 mt-2">
                  <span className="fw-semibold">Private feedback for Advisor</span>
                  &nbsp;(optional)
                </div>
                <textarea
                  className="form-control t--advisor-feedback"
                  rows={4}
                  placeholder='Use this space to share any additional feedback with your Advisor that you want to keep private between the two of you.'
                  onChange={(e) => setAdvisorFeedback(e.target.value) }
                  value={advisorFeedback || ""}
                />
                <div className="fs-md text-gray-900 mb-1 mt-2">
                  <span className="fw-semibold">Private feedback to Sundial</span>
                  &nbsp;(optional)
                </div>
                <textarea
                  className="form-control t--sundial-feedback"
                  rows={4}
                  placeholder='Use this space to share confidential feedback about your Advisor with the Sundial team. This information will not be shared with your Advisor.'
                  onChange={(e) => setSundialFeedback(e.target.value) }
                  value={sundialFeedback || ""}
                />
              </div>
            </>
          }
          footer={
            <button className="btn btn-primary" onClick={() => ratePurchase()} disabled={isSaving}>
              Save
            </button>
          }
          onDismiss={() => setShowModal(false)}
        />
      )}
    </>
  );
}

export const FiveStarRating = ({ rating, setRating, starClass }) => {
  const [hover, setHover] = useState(null);
  return (
    <div>
      {[1, 2, 3, 4, 5].map((star, index) => {
        const currentRating = index + 1;

        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              className="d-none"
              value={currentRating}
              onChange={() => setRating(currentRating)}
            />
            <span
              className="cursor-pointer"
              onMouseEnter={() => setHover(currentRating)}
              onMouseLeave={() => setHover(null)}
            >
              <i className={`ai-star${currentRating <= (hover || rating) ? '-filled' : ''} ${starClass}`}/>
            </span>
          </label>
        );
      })}
    </div>
  );
}

export const ReadOnlyRating = ({ rating, starClass }) => {
  return (
    <div>
      {[1, 2, 3, 4, 5].map((star, index) => {
        const currentRating = index + 1;
        return (
          <span key={index}>
            <i className={`ai-star${currentRating <= rating ? '-filled' : ''} ${starClass}`} />
          </span>
        );
      })}
    </div>
  );
}

export const ViewSubmittedRating = (customerRating: CustomerRating) => {
  return (
    <>
      <div className="mt-3">
        <div className="fs-md fw-semibold text-gray-900 mb-1">Rating</div>
        <ReadOnlyRating rating={customerRating.rating} starClass='h2 mb-0 text-primary pe-1'/>
      </div>
      <div className="mt-3">
        {maybeHtml(customerRating.ratingComment, (publicFeedback) => (
          <>
            <div className="fs-md text-gray-900 fw-semibold mb-1">Public Rating Comment</div>
            <div className="fs-md mt-1">{publicFeedback}</div>
          </>
        ))}
        {maybeHtml(customerRating.advisorFeedback, (advisorFeedback) => (
          <>
            <div className="fs-md text-gray-900 fw-semibold mt-2 mb-1">Private feedback for Advisor</div>
            <div className="fs-md mt-1">{advisorFeedback}</div>
          </>
        ))}
        {maybeHtml(customerRating.sundialFeedback, (sundialFeedback) => (
          <>
            <div className="fs-md text-gray-900 fw-semibold mt-2 mb-1">Private feedback for Sundial</div>
            <div className="fs-md mt-1">{sundialFeedback}</div>
          </>
        ))}
      </div>
    </>
  )
}
