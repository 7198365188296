import * as React from 'react';
import ReviewCard from './ReviewCard';
import { EssayReview } from '../Types/Essays';

type Props =
  { reviews: EssayReview[]
  }

const StandaloneRequestCards = (props: Props) => (
  <div className="row mt-3">
    {props.reviews.map((review) => (
      <div className="col-md-6 col-lg-4 mb-2">
          <ReviewCard review={review}/>
      </div>
    ))}
  </div>
)

export default StandaloneRequestCards;
