import * as React from 'react';
import { useState, useEffect } from 'react';
import { Advisor } from '../Types/Advisor';
import { AdvisorCard } from './ConsultantCard';

type Props =
  { advisors: Advisor[]
  , targetBlank?: boolean
  }

const ConsultantGrid = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const [filteredAdvisors, setFilteredAdvisors] = useState(props.advisors);

  // This hook serves to re-sort and re-filter the list of events when the search text is changed
  useEffect(() => {
    const filtered = props.advisors.filter((advisor) => {
      const searchFields = [
        advisor.preferredName,
        advisor.location
      ];
      return (
        searchFields.some((field) =>
          field?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });

    setFilteredAdvisors(filtered);
  }, [searchText])

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchText(query);
  }

  return (
    <>
      {/* <div className="row mt-3 align-items-center">
        <div className="col-md-8">
          <div className="input-group align-items-center p-0 bg-nav-background">
            <div className="input-group-prepend ms-2">
              <i className="ai-search"></i>
            </div>
            <input
              className="form-control"
              type="search"
              value={searchText}
              onChange={handleSearchInputChange}
              placeholder="Search advisors…"
            />
          </div>
        </div>
      </div> */}
      <div className="row mt-3">
        {filteredAdvisors.map((advisor) => (
          <div className="col-md-6 col-lg-4 mb-2">
              <AdvisorCard advisor={advisor} targetBlank={props.targetBlank}/>
          </div>
        ))}
      </div>
    </>
  )
}

export default ConsultantGrid;
