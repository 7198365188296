import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as API from '../../../Utils/API';
import { Advisor, InquiryMode } from '../../../Types/Advisor';
import { htmlIf } from '../../../Utils/HTML';

type Props =
  { advisor: Advisor
  }


export const ProfileCustomization = (props: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inquiryMode, setInquiryMode] = useState(props.advisor.inquiryMode);

  function save() {
    const postBody = {
      advisorId: props.advisor.id,
      inquiryMode: inquiryMode
    }

    API.post("advisor_account_set_profile_customization_path", postBody).then(function (result) {
      setIsEditing(false)
    })
  }

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between py-1">
        <h4 className="mb-0">Profile Customization</h4>
        {htmlIf(!isEditing,
          <button onClick={() => setIsEditing(true)} className="btn btn-link d-flex">
            <i className="ai-edit-alt me-1"></i>
            Edit
          </button>
        )}
      </div>
      <div className="card-body">
        <div>
          <h5 className="mb-1">Inquiry Mode</h5>
          <div className="">
            Inquiries are the best way for prospective clients to indicate interest in working with you.
            Families can make an inquiry via your profile to either request a free consultation (recommended)
            or request more information. After an inquiry is submitted by a prospective client, you’ll get
            an automated direct message from the client and be able to continue the conversation.
          </div>
          <select className="form-select mt-1"
            value={inquiryMode}
            onChange={(event) => setInquiryMode(event.target.value as InquiryMode)}
            disabled={!isEditing}
          >
            <option value={InquiryMode.FREE_CONSULTATION}>Request a free consultation (recommended)</option>
            <option value={InquiryMode.MORE_INFORMATION}>Request more information</option>
            <option value={InquiryMode.DISABLED}>Disabled (not recommended – clients will not be able to contact you!)</option>
          </select>
        </div>
        {isEditing && (
          <div className="text-end mt-3">
            <button className="btn btn-primary px-3" onClick={() => save()}>
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
