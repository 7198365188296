import * as React from 'react';
import { useState } from 'react';
import * as API from '../../../Utils/API';
import Select from 'react-select';

type Props =
  { allCalendars: Calendar[]
  , writeCalendarId?: string
  , availabilityCalendarIds: string[]
  , availabilityEndTimeOffsetDays?: number
  , availabilityStartTimeOffsetHours?: number
  }

type Calendar =
  { id: string
  , name: string
  , readonly: boolean
  }

type ReactSelectOption =
  { value: any
  , label: string
  }

// TODO: Allow control of offsets
const Calendars = (props: Props) => {
  const [allCalendars, setAllCalendars] = useState(props.allCalendars);
  const [writeCalendarId, setWriteCalendarId] = useState(props.writeCalendarId);
  const [availabilityCalendarIds, setAvailabilityCalendarIds] = useState(props.availabilityCalendarIds);
  const [endTimeOffsetDays, setEndTimeOffsetDays] = useState(props.availabilityEndTimeOffsetDays);
  const [startTimeOffsetHours, setStartTimeOffsetHours] = useState(props.availabilityStartTimeOffsetHours);
  const [isEditing, setIsEditing] = useState(false);

  function setWriteCalendar(calendar: Calendar) {
    const postBody = {calendarId: calendar.id}
    API.post("advisor_account_scheduling_set_write_calendar_path", postBody).then(function (result) {
      setWriteCalendarId(calendar.id)
    })
  }

  function setAvailabilityCalendars(calendars: Calendar[]) {
    const calendarIds = calendars.map((cal) => cal.id);
    const postBody = {calendarIds: calendarIds}
    API.post("advisor_account_scheduling_set_availability_calendars_path", postBody).then(function (result) {
      setAvailabilityCalendarIds(calendarIds)
    })
  }

  function saveOffsets() {
    // API Call
    // { availabilityEndTimeOffsetDays: state.availabilityEndTimeOffsetDays
    //   , availabilityStartTimeOffsetHours: state.availabilityStartTimeOffsetHours
    //   }
  }

  return (
    <>
      <div className="card">
        <div className="card-body row">
          <div className="col-md-6 mb-3 mb-md-0">
            <h3>Select event calendar</h3>
            <div className="fs-md">
              This is the calendar that events will be written to.
              If you don’t pick one, events may be written to any of your connected calendars.
            </div>
            <Select
              className="rounded mt-1"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              options={allCalendars.filter((cal) => !cal.readonly).map((cal) => ({value: cal.id, label: cal.name}))}
              onChange={(newCal: ReactSelectOption) => setWriteCalendar(
                allCalendars.filter((cal) => cal.id === newCal.value)[0]
              )}
              value={{value: writeCalendarId, label: allCalendars.filter((cal) => cal.id === writeCalendarId)[0]?.name }}
              placeholder="Select a calendar…"
            />
          </div>
          <div className="col-md-6 mb-3 mb-md-0">
            <h3>Select availability calendars</h3>
            <div className="fs-md">
              The calendars selected here will be used to determine your availability for sessions.
              If nothing is selected, all of your calendars will be used.
            </div>
            <Select
              className="rounded mt-1"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={allCalendars.map((cal) => ({value: cal.id, label: cal.name}))}
              onChange={(newCalendars: ReactSelectOption[]) => setAvailabilityCalendars(
                allCalendars.filter((cal) => newCalendars.map((item) => item.value).includes(cal.id))
              )}
              value={allCalendars.filter((cal) => availabilityCalendarIds.includes(cal.id)).map((cal) => ({value: cal.id, label: cal.name}))}
              placeholder="Select calendars…"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Calendars;
