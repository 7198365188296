import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import * as API from '../../../Utils/API';
import { Advisor, Degree, DegreeType } from '../../../Types/Advisor';
import { htmlIf } from '../../../Utils/HTML';
import { College, collegeToReactSelectItem, reactSelectItemToCollege } from '../../../Types/College';
import { DismissibleModal } from '../../../Components/Modal';

type Props =
  { advisor: Advisor
  , allColleges: College[]
  , allDegreeTypes: DegreeType[]
  }

enum Modal
  { NoModal
  , EditDegreeModal
  }

type ReactSelectOption =
  { value: any
  , label: string
  }

export const DegreeManager = (props: Props) => {
  const [advisor, setAdvisor] = useState(props.advisor);

  const [editingDegreeId, setEditingDegreeId] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [modal, setModal] = useState(Modal.NoModal);
  const [college, setCollege] = useState<College>();
  const [degreeType, setDegreeType] = useState<DegreeType>();
  const [description, setDescription] = useState('');
  const [graduationYear, setGraduationYear] = useState('');

  function clearStateDegreeFields() {
    setEditingDegreeId(0);
    setCollege(null);
    setDegreeType(null);
    setDescription(null);
    setGraduationYear(null);
  }

  function closeModalAndResetFields() {
    clearStateDegreeFields();
    setModal(Modal.NoModal);
  }

  function createDegree() {
    setIsSaving(true);

    const postBody = {
      collegeId: college.id,
      degreeTypeId: degreeType.id,
      description: description,
      graduationYear: graduationYear
    }

    API.post("advisor_account_create_degree_path", postBody).then(function (result) {
      setAdvisor(result['advisor']);
      closeModalAndResetFields();
      setIsSaving(false);
    })
  }

  function startEditingDegree(degree: Degree) {
    setEditingDegreeId(degree.id)
    setCollege(degree.college);
    setDegreeType(degree.degreeType);
    setDescription(degree.description);
    setGraduationYear(degree.graduationYear?.toString());
  }

  function saveEdits() {
    setIsSaving(true);

    const postBody = {
      degreeId: editingDegreeId,
      collegeId: college.id,
      degreeTypeId: degreeType.id,
      description: description,
      graduationYear: graduationYear
    }

    API.post("advisor_account_update_degree_path", postBody).then(function (result) {
      setAdvisor(result['advisor']);
      clearStateDegreeFields();
      setIsSaving(false);
    })
  }

  function deleteDegree(degree: Degree) {
    setIsSaving(true);
    const postBody = { degreeId: degree.id }

    API.post("advisor_account_delete_degree_path", postBody).then(function (result) {
      setAdvisor(result['advisor']);
      clearStateDegreeFields();
      setIsSaving(false);
    })
  }

  const CollegeReactSelect = () => (
    <Select
      className="rounded mt-1"
      classNames={{
        control: (state) => 'form-control p-1 rounded-2',
        multiValue: (state) => 'rounded-1',
        dropdownIndicator: (state) => 'py-0'
      }}
      options={props.allColleges.map((school) => (collegeToReactSelectItem(school)))}
      onChange={(newSchool: ReactSelectOption) => setCollege(
        reactSelectItemToCollege(newSchool, props.allColleges)
      )}
      value={collegeToReactSelectItem(college)}
      placeholder="Select degree type…"
    />
  )

  const DegreeTypeReactSelect = () => (
    <Select
      className="rounded mt-1"
      classNames={{
        control: (state) => 'form-control p-1 rounded-2',
        multiValue: (state) => 'rounded-1',
        dropdownIndicator: (state) => 'py-0'
      }}
      options={props.allDegreeTypes.map((dt) => ({value: dt.id, label: dt.name}))}
      onChange={(newType: ReactSelectOption) => setDegreeType(
        props.allDegreeTypes.filter((dt) => dt.id === newType.value )[0]
      )}
      value={{value: degreeType?.id, label: degreeType?.name }}
      placeholder="Select degree type…"
    />
  )

  const GraduationYearInput = () => (
    <input
      type='number'
      onChange={(e) => setGraduationYear(e.target.value)}
      className="form-control"
      value={graduationYear}
    />
  )

  const DescriptionInput = () => (
    <input
      onChange={(e) => setDescription(e.target.value)}
      maxLength={150}
      className="form-control"
      placeholder='i.e. History with a minor in Art'
      value={description}
    />
  )

  const ViewModal = () => {
    switch (modal) {
      case Modal.NoModal:
        return null
      case Modal.EditDegreeModal:
        return (
          <DismissibleModal
            title={<h4>Add a degree</h4>}
            dialogClass="modal-lg"
            body={
              <>
                <div className="fs-md fw-bold">School</div>
                {CollegeReactSelect()}
                <div className="fs-md fw-bold mt-2">Degree Type</div>
                {DegreeTypeReactSelect()}
                <div className="fs-md fw-bold mt-2 mb-1">Graduation Year</div>
                {GraduationYearInput()}
                <div className="fs-md fw-bold mt-2 mb-1">Description</div>
                {DescriptionInput()}
                <button className="btn btn-primary mt-4" onClick={createDegree} disabled={isSaving}>
                  Save
                </button>
              </>
            }
            onDismiss={closeModalAndResetFields}
          />
        )
    }
  }

  const DegreeCard = (degree: Degree) => {
    return (
      <div className="card h-100 mb-2">
        <div className="card-body p-3">
          {htmlIf(editingDegreeId !== degree.id,
            <div className="text-end">
              <button className="btn btn-link py-0" onClick={() => startEditingDegree(degree)}>
                <i className="ai-edit-alt text-primary fw-bold me-1" />
                Edit
              </button>
            </div>
          )}
          <div className="fs-md fw-bold">School</div>
          { editingDegreeId === degree.id
          ? CollegeReactSelect()
          : <div className="">{degree.college.name}</div>
          }
          <div className="fs-md fw-bold mt-3">Degree Type</div>
          { editingDegreeId === degree.id
          ? DegreeTypeReactSelect()
          : <div className="">{degree.degreeType.name}</div>
          }
          <div className="fs-md fw-bold mt-3">Graduation Year</div>
          { editingDegreeId === degree.id
          ? GraduationYearInput()
          : <div className="">{degree.graduationYear?.toString()}</div>
          }
          <div className="fs-md fw-bold mt-3">Description</div>
          { editingDegreeId === degree.id
          ? DescriptionInput()
          : <div className="">{degree.description}</div>
          }
          {htmlIf(editingDegreeId === degree.id,
            <div className="d-flex justify-content-end">
              <button className="btn btn-link text-danger mt-3" onClick={() => deleteDegree(degree)} disabled={isSaving}>
                <i className="ai-trash text-danger fw-bold me-1" />
                Delete
              </button>
              <button className="btn btn-primary mt-3 ms-3 px-4" onClick={() => saveEdits()} disabled={isSaving}>
                Save
              </button>

            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="card">
      {ViewModal()}
      <div className="card-header d-flex align-items-center justify-content-between py-2">
        <h4 className="mb-0">Education History</h4>
        <button onClick={() => setModal(Modal.EditDegreeModal)} className="btn btn-outline-primary btn-sm">
          Add degree
        </button>
      </div>
      <div className="card-body row">
        {advisor.degrees.map((degree, index) => (
          <div className="col-lg-6 mb-2">
            {DegreeCard(degree)}
          </div>
        ))}
      </div>
    </div>
  );
}
