import * as React from 'react';
import { useState } from 'react';
import { Advisor, AdvisorFunFact, advisorMessagesUrl, InquiryMode } from '../Types/Advisor';
import { htmlIf, formatMultiParagraphString, maybeHtml } from '../Utils/HTML';
import * as Language from '../Utils/Language';
import { formatUSDWithoutCents } from '../Utils/Currency';
import { EssayPackage } from '../Types/Essays';
import { CustomerRating } from '../Types/CustomerRating';
import InquiryModalButton from './InquiryModalButton';
import { AdvisingPackage, AdvisingSession, formatPackageHourlyRate } from '../Types/Advising';

export type Props =
  { advisor: Advisor
  , essayPackages: EssayPackage[]
  , advisingPackages: AdvisingPackage[]
  , advisingSessions: AdvisingSession[]
  , reviews: CustomerRating[]
  , funFacts: AdvisorFunFact[]
  , hasExistingMessages: boolean
  , isAnonymous: boolean
  }

const consultantDirectoryPath = '/consultants';

function essaysSubmitPath(advisor: Advisor) {
  return `/essays/submit/${advisor.id}`
}

function advisingPackagePurchasePath(pkg: AdvisingPackage) {
  return `/advising/packages/purchase/${pkg.id}`
}

function advisingSessionPurchasePath(session: AdvisingSession) {
  return `/advising/purchase/${session.advisorId}`
}

export const ConsultantProfile = (props: Props) => {
  const ViewHeader = () => {
    return (
      <div className="card rounded-4">
        <div className="card-img-top bg-orange-300 rounded-top" style={{paddingTop: '7rem'}}/>
        <div className="d-none d-lg-block">
          <div className="row align-items-end mb-3">
            <div className="col-auto mt-n1">
              <div className="avatar avatar-xxl ms-2" style={{marginTop: '-5rem'}}>
                <img className="avatar-img rounded-circle border-white border border-4" src={props.advisor.imageUrl}/>
              </div>
            </div>
            <div className="col me-3">
              <div className="row align-items-center">
                <div className="col-auto">
                  <div className="h4 mb-0">{props.advisor.preferredName}</div>
                </div>
                {htmlIf((props.advisor.averageRating !== null && props.advisor.numRatings > 1),
                  <div className="col-auto text-dark d-flex align-items-center">
                    <i className="ai-star-filled fs-xl text-warning me-1"/>
                    {props.advisor.averageRating?.toFixed(1)}
                    <span className="text-secondary ms-1">({props.advisor.numRatings} reviews)</span>
                  </div>
                )}
                <div className="col text-end">
                  { props.hasExistingMessages
                  ? <a className="btn btn-outline-secondary rounded-1" href={advisorMessagesUrl(props.advisor)}>
                      Messages
                    </a>
                  : props.advisor.inquiryMode !== InquiryMode.DISABLED
                    ? <InquiryModalButton
                        advisor={props.advisor}
                        isAnonymous={props.isAnonymous}
                        btnClass="btn btn-outline-primary rounded-1 t--inquiry-modal-button"
                        btnContent={
                          <>
                            <i className="ai-mail me-1"/>
                            { props.advisor.inquiryMode === InquiryMode.FREE_CONSULTATION
                            ? "Request a free consultation"
                            : props.advisor.inquiryMode === InquiryMode.MORE_INFORMATION
                              ? "Request more information"
                              : ""
                            }
                          </>
                        }
                      />
                    : null
                  }
                  {htmlIf(props.essayPackages.length > 0,
                    <a className="btn btn-primary rounded-1 ms-2" href={essaysSubmitPath(props.advisor)}>
                      Essay Review
                    </a>
                  )}
                  {/* <a className="btn btn-primary ms-2 rounded-1" href={'/essays'}>
                    Book a session
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-none text-center card-body p-3 pt-0">
          {/* Note: Manually overriding the width and height here — our avatar-xxl class forces a smaller avatar-xxl on narrow screens. */}
          <div className="avatar avatar-xxl" style={{marginTop: '-5rem', width: '8rem', height: '8rem'}}>
            <img className="avatar-img rounded-circle border-white border border-4" src={props.advisor.imageUrl}/>
          </div>
          {htmlIf((props.advisor.averageRating !== null && props.advisor.numRatings > 1),
            <div className="text-dark d-flex align-items-center justify-content-center mt-2">
              <i className="ai-star-filled fs-xl text-warning me-1"/>
              {props.advisor.averageRating?.toFixed(1)}
              <span className="text-secondary ms-1">({props.advisor.numRatings} reviews)</span>
            </div>
          )}
          <div className="h4 my-2">{props.advisor.preferredName}</div>
          {/* <div className="">
            <a className="btn btn-sm btn-primary w-100 rounded-1" href={'/essays'}>
              Book a session
            </a>
          </div> */}
          { props.hasExistingMessages
            ? <a className="btn btn-sm btn-outline-secondary w-100 rounded-1" href={advisorMessagesUrl(props.advisor)}>
                Messages
              </a>
            : <InquiryModalButton
                advisor={props.advisor}
                isAnonymous={props.isAnonymous}
                btnClass="btn btn-sm btn-outline-primary w-100 rounded-1"
                btnContent={
                  <>
                    <i className="ai-mail me-1"/>
                    Request a free consultation
                  </>
                }
              />
            }
          <div className="mt-2">
            <a className="btn btn-sm btn-primary w-100 rounded-1" href={essaysSubmitPath(props.advisor)}>
              Essay Review
            </a>
          </div>
        </div>
      </div>
    )
  }

  const ViewAbout = () => (
    <div className="card mt-3 rounded-1">
      <div className="card-body p-3">
        <div className="h3 mb-1">About</div>
        <div className="fs-md mt-2">{formatMultiParagraphString(props.advisor.bio)}</div>
        <div className="row mt-2">
          <div className="col-md-6">
            {maybeHtml(props.advisor.location, (location) => (
              <div className="d-flex align-items-center">
                <i className="ai-map-pin me-1 fs-xl" />
                {location}
              </div>
            ))}
            {maybeHtml(props.advisor.yearsOfExperience, (experience) => (
              <div className="d-flex align-items-center mt-1">
                <i className="ai-briefcase me-1 fs-xl" />
                {experience} years of experience
              </div>
            ))}
            {htmlIf(props.advisor.languageCodes.length > 0,
              <div className="d-flex align-items-center mt-1">
                <i className="ai-globe me-1 fs-xl" />
                <div>
                  {props.advisor.languageCodes.map((code, index) => (
                    <React.Fragment key={index}>
                      {Language.name(code)}
                      {index < props.advisor.languageCodes.length - 1 ? " | " : ""}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="col-md-6 mt-1 mt-md-0">
            {htmlIf(props.advisor.degrees.length > 0,
              <div className="d-flex">
                {/* <i className="ai-book me-1 fs-xl" /> */}
                <div className="fs-xxl me-1">🎓</div>
                <div>
                  {props.advisor.degrees.map((degree, index) => (
                    <div className="mb-1" key={index}>{degree.displayText}</div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  const ViewHowIWork = () => (
    <div className="card mt-3 rounded-1">
      <div className="card-body p-3">
        <div className="h3 mb-1">How I work</div>
        <div className="fs-md mt-2">{formatMultiParagraphString(props.advisor.advisingProcess)}</div>
        {htmlIf(props.advisor.colleges.length > 0,
          <div className="mt-3 px-3 py-2 bg-yellow-50 border rounded-1 border-warning">
            <div className="h5 mb-0">I’ve helped students get accepted to…</div>
            <div className="row mt-2">
              {props.advisor.colleges.map((college, index) => (
                <div className="col-md-6 fs-md mb-1" key={index}>{college.name}</div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )

  const ViewServices = () => (
    <div className="card mt-3 rounded-1">
      <div className="card-body p-3">
        <div className="h3">Services</div>
        {htmlIf(props.advisingSessions.length > 0,
          <div className="border border-gray-200 p-3 rounded-1 mt-3">
            <div className="h4 mb-1">Advising sessions</div>
            {props.advisingSessions.map((session, index) => (
              <div className="py-2">
                <div className="fs-md fw-semibold text-dark">{session.name}</div>
                <div className="fs-md mt-1">
                  <span className="fw-semibold">{formatUSDWithoutCents(session.amountCents)}</span>
                   | {session.numMinutes} min
                </div>
                {maybeHtml(session.description, (description) => (
                  <div className="fs-md mt-1">{description}</div>
                ))}
                <a href={advisingSessionPurchasePath(session)} className="btn btn-link p-0 mt-1">Book session</a>
              </div>
            ))}
          </div>
        )}
        {htmlIf(props.advisingPackages.length > 0,
          <div className="border border-gray-200 p-3 rounded-1 mt-3">
            <div className="h4 mb-1">Advising packages</div>
            {props.advisingPackages.map((advisingPkg, index) => (
              <div className="py-2">
                <div className="fs-md fw-semibold text-dark">{advisingPkg.name}</div>
                <div className="fs-xxl">{advisingPkg.numMinutes / 60} hours</div>
                {maybeHtml(advisingPkg.description, (description) => (
                  <div className="fs-md mt-1">{description}</div>
                ))}
                <div className="fs-lg text-dark mb-1 mt-1">
                  <span className="fw-semibold">{formatUSDWithoutCents(advisingPkg.totalPriceCents)}</span>
                  <span className="text-secondary"> ({formatPackageHourlyRate(advisingPkg)}/hr)</span>
                </div>

                <a href={advisingPackagePurchasePath(advisingPkg)} className="btn btn-link p-0">Book package</a>
              </div>
            ))}
          </div>
        )}
        {htmlIf(props.essayPackages.length > 0,
          <div className="border border-gray-200 p-3 rounded-1 mt-3">
            <div className="h4 mb-1">Essay review</div>
            {props.essayPackages.map((essayPkg, index) => (
              <div className="py-2">
                <div className="fs-md fw-semibold text-dark">{essayPkg.name}</div>
                <div className="fs-md text-dark mb-1">
                  {essayPkg.basePriceCents > 0 && essayPkg.pricePerWordCents > 0 ? (
                    `${formatUSDWithoutCents(essayPkg.basePriceCents)} + ${essayPkg.pricePerWordCents}¢ per word`
                  ) : essayPkg.basePriceCents > 0 ? (
                    formatUSDWithoutCents(essayPkg.basePriceCents)
                  ) : (
                    `${essayPkg.pricePerWordCents}¢ per word`
                  )}
                </div>
                {maybeHtml(essayPkg.description, (description) => (
                  <div className="fs-md">{description}</div>
                ))}
                <a href={essaysSubmitPath(props.advisor)} className="btn btn-link p-0">Submit essay</a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )

  const ViewFunFacts = () => (
    <div className="card mt-3 rounded-1">
      <div className="card-body p-3 pb-1">
        <div className="h3 mb-3">Fun facts</div>
        {props.funFacts.map((funFact) => (
          <div key={funFact.emoji} className="row g-0 no-gutters align-items-center mb-2">
            <div className="col-auto justify-content-center">
              <div className="avatar avatar-md me-2">
                <div className="avatar-title bg-accent rounded-1 lead">{funFact.emoji}</div>
              </div>
            </div>
            <div className="col">
              <div className="fs-sm">{funFact.question}</div>
              <div className="fs-md text-dark">{funFact.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const ViewReviews = () => {
    const [numReviewsToDisplay, setNumReviewsToDisplay] = useState(5);

    const ViewSingleReview = (review: CustomerRating, showBorder: boolean) => (
      <div key={review.id} className={`pb-3 mb-2 ${showBorder ? 'border-bottom' : ''}`}>
        <div className="fs-md mb-1 fw-bold text-dark">{review.ratedByName}</div>
        <div className="d-flex align-items-center mb-1">
          {Array.from({ length: review.rating }, (_, index) => (
            <i key={index} className="ai-star-filled text-warning fs-lg" />
          ))}
          <div className="ms-1 fs-lg text-dark">{review.rating}</div>
          <div className="ms-1 fs-sm">| {review.createdTimeAgo}</div>
        </div>
        <div className="fs-md">{review.ratingComment}</div>
      </div>
    )

    return (
      <div className="card mt-3 rounded-4">
        <div className="card-body py-3">
          <div className="fs-xl fw-bold text-dark mb-2">Reviews</div>
          {props.reviews.slice(0, numReviewsToDisplay).map((review, index) => (
            ViewSingleReview(review, !(index + 1 === numReviewsToDisplay))
          ))}
          {htmlIf(numReviewsToDisplay < props.reviews.length,
            <button className="w-100 btn btn-link py-0 mt-n2" onClick={() => setNumReviewsToDisplay(numReviewsToDisplay + 5)}>Show more</button>
          )}
        </div>
      </div>
    );
  }


  return (
    <div className="offset-xl-1 col-xl-10">
      <div className="col-auto d-flex align-items-center mb-2">
        <a href={consultantDirectoryPath} className="text-decoration-none align-items-center d-flex">
          <i className="ai-arrow-left me-1"/>
          <i className="ai-user-group me-1"/>
          All Advisors
        </a>
      </div>
      <ViewHeader />
      <ViewAbout />
      <ViewHowIWork />
      {htmlIf(props.essayPackages.length > 0 || props.advisingPackages.length > 0 || props.advisingSessions.length > 0,
        <ViewServices />
      )}
      {htmlIf(props.reviews.length > 0,
        <ViewReviews />
      )}
      {htmlIf(props.funFacts.length > 0,
        <ViewFunFacts />
      )}
    </div>
  )
}

export default ConsultantProfile;
