import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as API from '../../../Utils/API';
import { htmlIf } from '../../../Utils/HTML';
import { DismissibleModal } from '../../../Components/Modal';
import { EssayReturnTimeTier } from '../../../Types/Essays';
import { formatUSDWithoutCents } from '../../../Utils/Currency';


type Props =
  { returnTimeTiers: EssayReturnTimeTier[]
  }

enum Modal
  { NoModal
  , EditReturnTimeTierModal
  }

export const ReturnTimeTiers = (props: Props) => {
  const [returnTimeTiers, setreturnTimeTiers] = useState(props.returnTimeTiers);
  const [editingTierId, seteditingTierId] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [modal, setModal] = useState(Modal.NoModal);
  const [returnTimeHours, setReturnTimeHours] = useState('');
  const [additionalChargeCents, setAdditionalChargeCents] = useState(0);
  const [activeForSale, setActiveForSale] = useState(true);

  function clearStateTierFields() {
    seteditingTierId(0);
    setReturnTimeHours('');
    setAdditionalChargeCents(0);
    setActiveForSale(true);
  }

  function closeModalAndResetFields() {
    clearStateTierFields();
    setModal(Modal.NoModal);
  }

  function createTier() {
    setIsSaving(true);

    const postBody = {
      returnTimeHours: returnTimeHours,
      additionalChargeCents: additionalChargeCents,
      activeForSale: activeForSale
    }

    API.post("advisor_account_essays_create_return_time_tier_path", postBody).then(function (result) {
      setreturnTimeTiers(result['returnTimeTiers']);
      closeModalAndResetFields();
      setIsSaving(false);
    })
  }

  function startEditingTier(tier: EssayReturnTimeTier) {
    seteditingTierId(tier.id)
    setReturnTimeHours(tier.returnTimeHours.toString())
    setAdditionalChargeCents(tier.additionalChargeCents)
    setActiveForSale(tier.activeForSale)
  }

  function saveEdits() {
    setIsSaving(true);

    const postBody = {
      tierId: editingTierId,
      returnTimeHours: returnTimeHours,
      additionalChargeCents: additionalChargeCents,
      activeForSale: activeForSale
    }

    API.post("advisor_account_essays_update_return_time_tier_path", postBody).then(function (result) {
      setreturnTimeTiers(result['returnTimeTiers']);
      clearStateTierFields();
      setIsSaving(false);
    })
  }

  function deleteTier(tier: EssayReturnTimeTier) {
    setIsSaving(true);
    const postBody = { tierId: tier.id }

    API.post("advisor_account_essays_delete_return_time_tier_path", postBody).then(function (result) {
      setreturnTimeTiers(result['returnTimeTiers']);
      clearStateTierFields();
      setIsSaving(false);
    })
  }

  const ReturnTimeHoursInput = () => (
    <>
      <div className="fs-sm text-secondary mb-1">Enter a value from 1 to 36</div>
      <input
        type='number'
        onChange={(e) => setReturnTimeHours(e.target.value)}
        className="form-control"
        min={0}
        max={36}
        value={returnTimeHours}
      />
    </>
  )

  const AdditionalChargeCentsInput = () => (
    <div className="input-group align-items-center p-0">
      <div className="input-group-prepend ms-2">$</div>
      <input
        className="form-control t--total-price-cents" type="number"
        value={additionalChargeCents / 100}
        min={0}
        onChange={(e) => setAdditionalChargeCents(parseInt(e.target.value) * 100)}
      />
    </div>
  )

  const ViewModal = () => {
    switch (modal) {
      case Modal.NoModal:
        return null
      case Modal.EditReturnTimeTierModal:
        return (
          <DismissibleModal
            title={<h4>Add a package</h4>}
            dialogClass="modal-lg"
            body={
              <>
                <div className="fs-md fw-bold mt-2">Return Time (in hours)</div>
                {ReturnTimeHoursInput()}
                <div className="fs-md fw-bold mt-2 mb-1">Additional Charge</div>
                {AdditionalChargeCentsInput()}
                <button className="btn btn-primary mt-4" onClick={createTier} disabled={isSaving}>
                  Save
                </button>
              </>
            }
            onDismiss={closeModalAndResetFields}
          />
        )
    }
  }

  const TierCard = (tier: EssayReturnTimeTier) => {
    return (
      <div className="card h-100 mb-2">
        <div className="card-body p-3">
          {htmlIf(editingTierId !== tier.id,
            <div className="text-end">
              <button className="btn btn-link py-0" onClick={() => startEditingTier(tier)}>
                <i className="ai-edit-alt text-primary fw-bold me-1" />
                Edit
              </button>
            </div>
          )}
          <div className="fs-md fw-bold mt-3">Return Time (in hours)</div>
          {editingTierId === tier.id
            ? ReturnTimeHoursInput()
            : <div className="">{tier.returnTimeHours.toString()}</div>
          }
          <div className="fs-md fw-bold mt-3">Additional Charge</div>
          {editingTierId === tier.id
            ? AdditionalChargeCentsInput()
            : <div className="">{formatUSDWithoutCents(tier.additionalChargeCents)}</div>
          }
          <div className="d-flex align-items-center mt-3">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                disabled={editingTierId !== tier.id}
                onChange={() => setActiveForSale(!activeForSale)}
                checked={editingTierId !== tier.id ? tier.activeForSale : activeForSale}
              />
            </div>
            <div className="ms-2">
              <h5 className="mb-1">Availability</h5>
              <div className="fs-md">
                {
                  editingTierId === tier.id ?
                    activeForSale
                      ? "This return time tier is currently available for purchase."
                      : "This return time tier is not available for purchase."
                    :
                    tier.activeForSale
                      ? "This return time tier is currently available for purchase."
                      : "This return time tier is not available for purchase."
                }
              </div>
            </div>
          </div>

          {htmlIf(editingTierId === tier.id,
            <div className="d-flex justify-content-end">
              <button className="btn btn-link text-danger mt-3" onClick={() => deleteTier(tier)} disabled={isSaving}>
                <i className="ai-trash text-danger fw-bold me-1" />
                Delete
              </button>
              <button className="btn btn-primary mt-3 ms-3 px-4" onClick={() => saveEdits()} disabled={isSaving}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="card">
      {ViewModal()}
      <div className="card-header d-flex align-items-center justify-content-between py-2">
        <h4 className="mb-0">Guaranteed Return Time Tiers</h4>
        <button onClick={() => setModal(Modal.EditReturnTimeTierModal)} className="btn btn-outline-primary btn-sm">
          Add tier
        </button>
      </div>
      <div className="card-body row">
        {returnTimeTiers.map((tier, index) => (
          <div className="col-lg-6 mb-2" key={index}>
            {TierCard(tier)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ReturnTimeTiers;
