import { User } from './User';
import { LanguageCode } from '../Utils/Language';
import { College } from './College';

export type Advisor =
  { id: number
  , userId: number
  , createdAt: string
  , active: boolean
  , validForPayment: boolean
  , isOpenToWork: boolean
  , bio: string
  , advisingProcess: string
  , location: string
  , shortEducation: string
  , yearsOfExperience: number
  , preferredName: string
  , pronouns: string
  , imageUrl: string
  , inquiryMode: InquiryMode
  , languageCodes: LanguageCode[]
  , professionalOrganizations: ProfessionalOrganization[]
  , colleges: College[]
  , degrees: Degree[]
  , lastSeenAt: string
  , averageRating?: number
  , numRatings: number
  , user?: User
  }

export type AdvisorFunFact =
  { question: string
  , answer: string
  , emoji: string
  }

export type ProfessionalOrganization =
  { id: number
  , name: string
  , abbreviation: string
  }

export type Degree =
  { id: number
  , college: College
  , degreeType: DegreeType
  , description: string
  , graduationYear: number
  , displayText: string
  }

export type DegreeType =
  { id: number
  , name: string
  , abbreviation: string
  }

export enum InquiryMode
  { DISABLED = 'disabled'
  , FREE_CONSULTATION = 'free-consultation'
  , MORE_INFORMATION = 'more-information'
  }

export function advisorProfilePath(advisorId: number) {
  return `/consultants/profile/${advisorId}`
}

export function advisorMessagesUrl(advisor: Advisor) {
  if (advisor) {
    return `/messages?other_user_id=${advisor.userId}`
  } else {
    return "/messages"
  }
}

export function advisorEssayReviewPath(advisorId: number) {
  return `/essays/submit/${advisorId}`
}
