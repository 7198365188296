import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as API from '../../../Utils/API';
import { Advisor } from '../../../Types/Advisor';
import { htmlIf } from '../../../Utils/HTML';

type Props =
  { advisor: Advisor
  , funFacts: AllFunFacts
  }

export type AllFunFacts = {
  advice: string;
  favoriteAlbum: string;
  favoriteAuthor: string;
  favoriteClass: string;
  favoriteFood: string;
  favoriteMovie: string;
  favoriteSport: string;
  firstJob: string;
  hobbies: string;
  threeWordDescription: string;
}


export const EditFunFacts = (props: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [state, setState] = useState<AllFunFacts>(props.funFacts);

  function saveFunFacts() {
    const postBody = {
      advisorId: props.advisor.id,
      advice: state.advice,
      favoriteAlbum: state.favoriteAlbum,
      favoriteAuthor: state.favoriteAuthor,
      favoriteClass: state.favoriteClass,
      favoriteFood: state.favoriteFood,
      favoriteMovie: state.favoriteMovie,
      favoriteSport: state.favoriteSport,
      firstJob: state.firstJob,
      hobbies: state.hobbies,
      threeWordDescription: state.threeWordDescription
    }

    API.post("advisor_account_update_fun_facts_path", postBody).then(function (result) {
      setIsEditing(false)
    })
  }

  const handleChange = (key: keyof AllFunFacts, value: string) => {
    setState(prevState => ({ ...prevState, [key]: value }));
  };

  const renderFunFact = (emoji: string, question: string, value?: string, key?: keyof AllFunFacts) => {
    if (isEditing) {
      return (
        <div className="mb-1">
          {question} {emoji}
          <input
            type="text"
            maxLength={140}
            value={value || ''}
            onChange={(event) => handleChange(key!, event.target.value)}
            className="form-control"
          />
        </div>
      );
    } else {
      return (
        <div className="row g-0 no-gutters align-items-center mb-2">
          <div className="col-auto justify-content-center">
            <div className="avatar avatar-md me-2">
              <div className="avatar-title bg-accent rounded-1 lead">{emoji}</div>
            </div>
          </div>
          <div className="col">
            <div className="fs-sm">{question}</div>
            {value
            ? <div className="fs-md text-dark">{value}</div>
            : <div className="fs-md text-secondary">Not set</div>
            }
          </div>
        </div>
      );
    }
  };

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between py-1">
        <h4 className="mb-0">Fun Facts</h4>
        {htmlIf(!isEditing,
          <button onClick={() => setIsEditing(true)} className="btn btn-link d-flex">
            <i className="ai-edit-alt me-1"></i>
            Edit
          </button>
        )}
      </div>
      <div className="card-body">
        <div className="row">
          {renderFunFact('⭐', 'Hobbies', state.hobbies, 'hobbies')}
          {renderFunFact('💬', 'Describe yourself in three words', state.threeWordDescription, 'threeWordDescription')}
          {renderFunFact('🗣', 'Favorite piece of advice', state.advice, 'advice')}
          {renderFunFact('🎬', 'Favorite movie', state.favoriteMovie, 'favoriteMovie')}
          {renderFunFact('🍽', 'Favorite cuisine', state.favoriteFood, 'favoriteFood')}
          {renderFunFact('📖', 'Favorite author', state.favoriteAuthor, 'favoriteAuthor')}
          {renderFunFact('🎓', 'Favorite classes in school', state.favoriteClass, 'favoriteClass')}
          {renderFunFact('🏟', 'Favorite sport', state.favoriteSport, 'favoriteSport')}
          {renderFunFact('🎧', 'Favorite album', state.favoriteAlbum, 'favoriteAlbum')}
          {renderFunFact('🧑🏼‍💼', 'First job', state.firstJob, 'firstJob')}
        </div>
        {isEditing && (
          <div className="text-end mt-3">
            <button className="btn btn-primary px-3" onClick={() => saveFunFacts()}>
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
