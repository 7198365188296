import * as React from 'react';
import { Advisor, DegreeType, ProfessionalOrganization } from '../../Types/Advisor';
import AboutMe from './Profile/AboutMe';
import { AllFunFacts, EditFunFacts } from './Profile/FunFacts';
import { College } from '../../Types/College';
import { DegreeManager } from './Profile/Degrees';
import { ProfileCustomization } from './Profile/ProfileCustomization';

type Props =
  { advisor: Advisor
  , allDegreeTypes: DegreeType[]
  , allProfessionalOrganizations: ProfessionalOrganization[]
  , allColleges: College[]
  , funFacts: AllFunFacts
  }

const Profile = (props: Props) => (
  <div>
    <AboutMe
      advisor={props.advisor}
      allProfessionalOrganizations={props.allProfessionalOrganizations}
      allColleges={props.allColleges}
    />
    <div className="mt-3" />
    <DegreeManager advisor={props.advisor} allColleges={props.allColleges} allDegreeTypes={props.allDegreeTypes} />
    <div className="mt-3" />
    <EditFunFacts advisor={props.advisor} funFacts={props.funFacts} />
    <div className="mt-3" />
    <ProfileCustomization advisor={props.advisor} />
  </div>
)

export default Profile;
