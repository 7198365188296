export type College =
  { id: number
  , name: string
  , abbreviation: string
  }

  export function collegeToReactSelectItem(college: College): { value: number, label: string } {
    return { value: college?.id, label: college?.name }
  }

  // Technically this function could also return `undefined` if no matching college is found
  // in the allColleges array, but for our purposes this is unlikely and the extra effort of
  // needing to deal with the optional College is not worth the squeeze here.
  export function reactSelectItemToCollege(item: { value: number, label: string }, allColleges: College[]): College {
    return allColleges.find((college) => college.id === item.value);
  }

