import * as React from 'react';
import { useState } from 'react';
import { EssayReview, ReviewStatus } from '../Types/Essays';
import { formatMultiParagraphString, htmlIf, maybeHtml } from '../Utils/HTML';
import { CustomerEssayStatusBadge } from '../Common/StatusBadges';
import { formatUSDWithCents } from '../Utils/Currency';
import { advisorProfilePath } from '../Types/Advisor';
import { FiveStarRating, PurchaseType, RatingModalButton, ViewSubmittedRating } from '../Common/Ratings';

type Props =
  { review: EssayReview
  , backUrl: string
  }

function messagesUrl(userId: number) {
  return `/messages?other_user_id=${userId}`
}

function formatDate(dateString: string):string {
  const date = new Date(dateString)
  return (
    date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
  )
}

const ReviewDetails = (props: Props) => {
  const [review, setReview] = useState(props.review);
  const [requestRating, setRequestRating] = useState(props.review.customerRating?.rating);
  const [ratingCompletedOnPageLoad, setRatingCompletedOnPageLoad] = useState(props.review.customerRating !== null);

  const ViewHeader = () => (
    <div className="p-3 border-bottom">
      <div className="">
        <div className="row align-items-center">
          <div className="col-lg d-lg-flex align-items-center">
            <h3 className="mb-0 me-1">#{review.id} ({review.packageName})</h3>
            <CustomerEssayStatusBadge review={review}/>
          </div>
          <div className="col-auto d-flex justify-content-end mt-1 mt-lg-0">
            <a className="btn btn-outline-primary px-3 t--view-messages" href={messagesUrl(review.customerUserId)}>
              Messages
            </a>
          </div>
        </div>
        <div className="mt-1">
          <div className="fs-sm">
            {htmlIf(!!review.paidAt,
              <>
                Purchased
                <span className=""> {formatDate(review.paidAt)}</span>
              </>
            )}
            {htmlIf(review.status === ReviewStatus.COMPLETED && !!review.completedAt,
              <>
                <span className="mx-1">•</span>
                Returned
                <span className=""> {formatDate(review.completedAt)}</span>
              </>
            )}
            {htmlIf(review.status === ReviewStatus.REFUNDED && !!review.refundedAt,
              <>
                <span className="mx-1">•</span>
                Refunded
                <span className=""> {formatDate(review.refundedAt)}</span>
              </>
            )}
          </div>
        </div>
        {htmlIf(review.guaranteedReturnTimeHours > 0,
          <div className="mt-1 fw-semibold">
            Guaranteed Return Time: {review.guaranteedReturnTimeHours} hours
          </div>
        )}
      </div>
    </div>
  )

  const ViewOverview = () => {
    return (
      <div className="p-3">
        <div className="fs-lg text-dark">Your reviewer</div>
        <a className="d-flex align-items-center mt-2 text-decoration-none cursor-pointer"
          href={advisorProfilePath(review.advisorId)} target='_blank'
        >
          <div className="avatar avatar-md">
            <img className="avatar-img rounded-circle" src={review.advisorImageUrl}/>
          </div>
          <div className="fs-md text-gray-900 ms-1">{review.advisorName}</div>
        </a>
        <div className="fs-lg text-dark mt-3 border-top pt-2">Submission details</div>
        {maybeHtml(review.paidAt, (paidAt) => (
          <>
            <div className="h6 mb-0 mt-2">Amount paid</div>
            <div className="">{formatUSDWithCents(review.amountCents)}</div>
          </>
        ))}
        <div className="h6 mb-0 mt-3">Essay prompt</div>
        <div className="">{review.essayPrompt}</div>
        <div className="h6 mb-0 mt-3">Word limit</div>
        <div className="">{review.essayWordLimit}</div>
        <div className="h6 mb-0 mt-3">Which schools is this esssay for?</div>
        <div className="">{review.essayColleges.join(' | ')}</div>
        <div className="h6 mb-0 mt-3">What are you looking for feedback on?</div>
        <div className="">{review.essayFeedbackRequest}</div>
      </div>
    )
  }

  const ViewCompletedEssayDetails = () => {
    return (
      <div className="p-3 border-bottom">
        {maybeHtml(review.essayDocxUrl, (docUrl) => (
          <div className="mb-3">
            <div className="fs-lg text-dark">Download your completed review here:</div>
            <a href={docUrl} target='_blank' className="btn btn-info mt-2">
              <i className="ai-external-link me-1" />
              Download essay
            </a>
          </div>
        ))}
        <div className="fs-lg text-dark">Overall feedback:</div>
        <div className="fs-md">{formatMultiParagraphString(review.advisorFeedback)}</div>
      </div>
    )
  }

  const ViewOriginalEssayText = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div className="p-3 cursor-pointer border-top" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="fs-lg text-dark">Original essay draft (click to expand)</div>
          <i className={`fs-lg ${isExpanded ? 'ai-chevron-up' : 'ai-chevron-down'}`} />
        </div>
        {htmlIf(isExpanded,
          <div className="fs-md mt-2">{formatMultiParagraphString(review.essayText)}</div>
        )}
      </div>
    )
  }

  const ViewRating = () => (
    <div className="p-3 bg-orange-50 border-bottom">

      {htmlIf(!ratingCompletedOnPageLoad, maybeHtml(review.customerRating, (rating) => (
        <div className="alert alert-info p-3">
          Rating submitted. Thank you for your feedback!
        </div>
      )))}
      <h5>{"How would you rate your experience with " + review.advisorName + " on this review?"}</h5>
      { review.customerRating
      ? <>
          {ViewSubmittedRating(review.customerRating)}
        </>
      : <>
          <div className="row mb-3">
            <FiveStarRating rating={requestRating} setRating={setRequestRating} starClass={'h2 mb-0 text-warning pe-1'}/>
          </div>
          <RatingModalButton
            purchaseType={PurchaseType.ESSAY_REVIEW}
            purchaseId={review.id}
            advisorName={review.advisorName}
            presetOverallRating={requestRating}
            buttonClass="btn btn-primary px-3 t--submit-rating"
            buttonText='Continue'
            purchaseSetter={setReview}
          />
        </>
      }

    </div>
  )

  return (
    <>
      <div className="row align-items-end">
        <div className="col-auto d-flex align-items-center">
          <a href={props.backUrl} className="text-decoration-none align-items-center d-flex">
            <i className="ai-arrow-left me-1"/>
            <i className="ai-grid me-1"/>
            All essays
          </a>
        </div>
      </div>
      <div className="card rounded-2 mt-1">
        {ViewHeader()}
        {htmlIf(review.status === ReviewStatus.COMPLETED,
          ViewCompletedEssayDetails()
        )}
        {htmlIf(review.status === ReviewStatus.COMPLETED, ViewRating())}
        {ViewOverview()}
        {htmlIf(review.status === ReviewStatus.AWAITING_PAYMENT,
          <div className="p-3 fs-lg border-top">
            It looks like we haven’t confirmed your payment information for this review just yet. Please contact 
            <a href="mailto:support@sundialessayreview.com">support@sundialessayreview.com</a> for additional
            assistance.
          </div>
        )}
        {ViewOriginalEssayText()}
        {htmlIf(review.status === ReviewStatus.REFUNDED,
          <div className="p-3 fs-md border-top">
            This review has been refunded and review details are no longer available.
          </div>
        )}
      </div>
    </>
  )
}

export default ReviewDetails;
