import * as React from 'react';
import { useState } from 'react';
import * as API from '../../../Utils/API';
import { formatMultiParagraphString, htmlIf } from '../../../Utils/HTML';
import { DismissibleModal } from '../../../Components/Modal';
import { AdvisingPackage } from '../../../Types/Advising';
import { formatUSDWithoutCents } from '../../../Utils/Currency';

type Props =
  { advisingPackages: AdvisingPackage[]
  }

enum Modal
  { NoModal
  , EditPackageModal
  }

const allowedRefundPeriodLengths = [45, 30, 14]
const defaultRefundPeriodLength = 45

export const AdvisingPackages = (props: Props) => {
  const [advisingPackages, setAdvisingPackages] = useState(props.advisingPackages);
  const [editingPackageId, setEditingPackageId] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [modal, setModal] = useState(Modal.NoModal);
  const [name, setName] = useState('')
  const [description, setDescription] = useState('');
  const [totalPriceCents, setTotalPriceCents] = useState(0)
  const [numMinutes, setNumMinutes] = useState('')
  const [refundPeriodLengthDays, setRefundPeriodLengthDays] = useState(defaultRefundPeriodLength)
  const [activeForSale, setActiveForSale] = useState(true)

  function clearStatePackageFields() {
    setEditingPackageId(0);
    setName('');
    setDescription('');
    setTotalPriceCents(0);
    setNumMinutes('');
    setActiveForSale(true);
    setRefundPeriodLengthDays(defaultRefundPeriodLength);
  }

  function closeModalAndResetFields() {
    clearStatePackageFields();
    setModal(Modal.NoModal);
  }

  function createPackage() {
    setIsSaving(true);

    const postBody = {
      name: name,
      description: description,
      totalPriceCents: totalPriceCents,
      numMinutes: numMinutes,
      refundPeriodLengthDays: refundPeriodLengthDays,
      activeForSale: activeForSale
    }

    API.post("advisor_account_advising_create_advising_package_path", postBody).then(function (result) {
      setAdvisingPackages(result['advisingPackages']);
      closeModalAndResetFields();
      setIsSaving(false);
    })
  }

  function startEditingAdvisingPackage(advisingPackage: AdvisingPackage) {
    setEditingPackageId(advisingPackage.id);
    setName(advisingPackage.name);
    setDescription(advisingPackage.description);
    setTotalPriceCents(advisingPackage.totalPriceCents);
    setNumMinutes(advisingPackage.numMinutes.toString());
    setRefundPeriodLengthDays(advisingPackage.refundPeriodLengthDays);
    setActiveForSale(advisingPackage.activeForSale);
  }

  function saveEdits() {
    setIsSaving(true);

    const postBody = {
      packageId: editingPackageId,
      name: name,
      description: description,
      totalPriceCents: totalPriceCents,
      numMinutes: numMinutes,
      refundPeriodLengthDays: refundPeriodLengthDays,
      activeForSale: activeForSale
    }

    API.post("advisor_account_advising_update_advising_package_path", postBody).then(function (result) {
      setAdvisingPackages(result['advisingPackages']);
      clearStatePackageFields();
      setIsSaving(false);
    })
  }

  function deletePackage(advisingPackage: AdvisingPackage) {
    setIsSaving(true);
    const postBody = { packageId: advisingPackage.id }

    API.post("advisor_account_advising_delete_advising_package_path", postBody).then(function (result) {
      setAdvisingPackages(result['advisingPackages']);
      clearStatePackageFields();
      setIsSaving(false);
    })
  }

  const TotalPriceCentsInput = () => (
    <div className="input-group align-items-center p-0">
      <div className="input-group-prepend ms-2">$</div>
      <input
        className="form-control t--total-price-cents" type="number"
        value={totalPriceCents / 100}
        min={0}
        onChange={(e) => setTotalPriceCents(parseInt(e.target.value) * 100)}
      />
    </div>
  )

  const NumMinutesInput = () => (
    <input
      type='number'
      onChange={(e) => setNumMinutes(e.target.value)}
      className="form-control"
      min={0}
      value={numMinutes}
    />
  )

  const DescriptionInput = () => (
    <textarea
      onChange={(e) => setDescription(e.target.value)}
      className="form-control"
      placeholder='Describe what this package includes and who it might be suited for…'
      rows={5}
      value={description}
    />
  )

  const NameInput = () => (
    <input
      onChange={(e) => setName(e.target.value)}
      maxLength={30}
      className="form-control"
      placeholder='Enter package title…'
      value={name}
    />
  )

  const RefundPeriodLengthDaysInput = () => (
    <select className="form-select bg-white"
      value={refundPeriodLengthDays} onChange={(e) => setRefundPeriodLengthDays(Number(e.target.value))}
    >
        {allowedRefundPeriodLengths.map((option) => (
            <option key={option} value={option}>
                {`${option} days`}
            </option>
        ))}
    </select>
  )

  const ViewModal = () => {
    switch (modal) {
      case Modal.NoModal:
        return null
      case Modal.EditPackageModal:
        return (
          <DismissibleModal
            title={<h4>Add a package</h4>}
            dialogClass="modal-lg"
            body={
              <>
                <div className="fs-md fw-bold mt-2 mb-1">Name</div>
                {NameInput()}
                <div className="fs-md fw-bold mt-2 mb-1">Total Price</div>
                {TotalPriceCentsInput()}
                <div className="fs-md fw-bold mt-2 mb-1">Number of Minutes</div>
                {NumMinutesInput()}
                <div className="fs-md fw-bold mt-2 mb-1">Refund Period Length</div>
                {RefundPeriodLengthDaysInput()}
                <div className="fs-md fw-bold mt-2 mb-1">Description</div>
                {DescriptionInput()}

                <button className="btn btn-primary mt-4" onClick={createPackage} disabled={isSaving}>
                  Save
                </button>
              </>
            }
            onDismiss={closeModalAndResetFields}
          />
        )
    }
  }

  const PackageCard = (advisingPackage: AdvisingPackage) => {
    return (
      <div className="card h-100 mb-2">
        <div className="card-body p-3">
          {htmlIf(editingPackageId !== advisingPackage.id,
            <div className="text-end">
              <button className="btn btn-link py-0" onClick={() => startEditingAdvisingPackage(advisingPackage)}>
                <i className="ai-edit-alt text-primary fw-bold me-1" />
                Edit
              </button>
            </div>
          )}

          <div className="fs-md fw-bold">Name</div>
          {editingPackageId === advisingPackage.id
            ? NameInput()
            : <div className="">{advisingPackage.name}</div>
          }
          <div className="fs-md fw-bold mt-3">Description</div>
          {editingPackageId === advisingPackage.id
            ? DescriptionInput()
            : <div className="">{formatMultiParagraphString(advisingPackage.description)}</div>
          }
          <div className="fs-md fw-bold mt-3">Total Price</div>
          {editingPackageId === advisingPackage.id
            ? TotalPriceCentsInput()
            : <div className="">{formatUSDWithoutCents(advisingPackage.totalPriceCents)}</div>
          }
          <div className="fs-md fw-bold mt-3">Package length (in minutes)</div>
          {editingPackageId === advisingPackage.id
            ? NumMinutesInput()
            : <div className="">{advisingPackage.numMinutes.toString()}</div>
          }
          <div className="fs-md fw-bold mt-3">Refund Period Length</div>
          {editingPackageId === advisingPackage.id
            ? RefundPeriodLengthDaysInput()
            : <div className="">{advisingPackage.refundPeriodLengthDays.toString()} days</div>
          }
          <div className="d-flex align-items-center mt-3">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                disabled={editingPackageId !== advisingPackage.id}
                onChange={() => setActiveForSale(!activeForSale)}
                checked={editingPackageId !== advisingPackage.id ? advisingPackage.activeForSale : activeForSale}
              />
            </div>
            <div className="ms-2">
              <h5 className="mb-1">Package Visibility</h5>
              <div className="fs-md">
                {
                  editingPackageId === advisingPackage.id ?
                    activeForSale
                      ? "This package is currently available for purchase."
                      : "This package is not available for purchase."
                    :
                    advisingPackage.activeForSale
                      ? "This package is currently available for purchase."
                      : "This package is not available for purchase."
                }
              </div>
            </div>
          </div>

          {htmlIf(editingPackageId === advisingPackage.id,
            <div className="d-flex justify-content-end">
              <button className="btn btn-link text-danger mt-3" onClick={() => deletePackage(advisingPackage)} disabled={isSaving}>
                <i className="ai-trash text-danger fw-bold me-1" />
                Delete
              </button>
              <button className="btn btn-primary mt-3 ms-3 px-4" onClick={() => saveEdits()} disabled={isSaving}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="card">
      {ViewModal()}
      <div className="card-header d-flex align-items-center justify-content-between py-2">
        <h4 className="mb-0">Advising Packages</h4>
        <button onClick={() => setModal(Modal.EditPackageModal)} className="btn btn-outline-primary btn-sm">
          Add package
        </button>
      </div>
      <div className="card-body row pt-2">
        <div className="fs-md mb-2">
            Advising packages allow clients to engage in an ongoing relationship with you over the course of multiple
            meetings as well via your logging of offline work.
          </div>
        {advisingPackages?.map((pkg, index) => (
          <div className="col-lg-6 mb-2" key={index}>
            {PackageCard(pkg)}
          </div>
        ))}
        <div className="fs-md mt-2">Advising packages will be displayed in order of ascending length on your profile (smallest to largest).</div>
      </div>
    </div>
  );
}

export default AdvisingPackages;
