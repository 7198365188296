import * as React from 'react';
import { useState, useEffect } from 'react';
import * as API from '../Utils/API.js';
import { Advisor } from '../Types/Advisor';
import { htmlIf, formatMultiParagraphString, maybeHtml } from '../Utils/HTML';
import { formatUSDWithCents, formatUSDWithoutCents } from '../Utils/Currency';
import Select from 'react-select';
import * as EmailValidator from 'email-validator';
import { AdvisingPackage } from '../Types/Advising.js';

type Props =
  { advisor: Advisor
  , package: AdvisingPackage
  , isAnonymous: boolean
  }

enum Step
  { PurchasePackage
  }


const PackageCheckout = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(Step.PurchasePackage);
  const [completedSteps, setCompletedSteps] = useState(new Set<Step>());

  const [showErrors, setShowErrors] = useState(false);

  const [anonFirstName, setAnonFirstName] = useState('');
  const [anonLastName, setAnonLastName] = useState('');
  const [anonEmail, setAnonEmail] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });

    setShowErrors(false);
  }, [currentStep])

  function purchasePackage() {
    setIsSubmitting(true);

    const postBody = {
      advisingPackageId: props.package.id,
      anonEmail: anonEmail,
      anonFirstName: anonFirstName,
      anonLastName: anonLastName
    }

    API.post("advising_packages_purchase_package_path", postBody).then(function (result) {
      setIsSubmitting(false);

      if (result['error']) {
        if (result['message']) {
          setErrorMessage(result['message'])
        } else {
          setErrorMessage("Something went wrong. Our team has been notified and will investigate the issue.")
        }
      } else {
        window.location.href = result['url']
      }
    })
  }

  const ViewAdvisorDetails = () => (
    <div className="p-3 pb-0 rounded-2">
      <div className="fs-lg fw-semibold text-dark">Your advisor</div>
      <div className="d-flex align-items-center mt-1">
        <div className="avatar avatar-xl">
          <img className="avatar-img rounded-circle" src={props.advisor.imageUrl}/>
        </div>
        <div className="ms-1">
          <div className="fs-md ms-1">{props.advisor.preferredName}</div>
          {htmlIf((props.advisor.averageRating !== null && props.advisor.numRatings > 1),
            <div className="d-flex align-items-center">
              <i className="ai-star me-1 text-primary"/>
              {props.advisor.averageRating?.toFixed(1)}
            </div>
          )}
        </div>
      </div>
    </div>
  )

  const ViewProgressNav = () => {
    const steps = [
      { number: 1, title: 'Confirm & Submit', icon: 'ai-check', step: Step.PurchasePackage },
    ]

    function navItemClickHandler(event: React.MouseEvent<HTMLAnchorElement>, step: Step) {
      if (completedSteps.has(step)) {
        event.preventDefault();
        setCurrentStep(step);
      } else {
        return
      }
    }

    return (
      <>
        <ViewAdvisorDetails />
        <div className="card-header mt-2">
          {steps.map((stepInfo, index) => (
            <div className={`${currentStep === index ? "bg-white border rounded-2" : ""} ${index > 0 ? "mt-1" : ""}`} key={index}>
              <a className={`px-2 py-1 d-flex align-items-center justify-content-between text-decoration-none
                  ${completedSteps.has(stepInfo.step) ? 'cursor-pointer' : ''}`}
                key={index}
                onClick={(e) => navItemClickHandler(e, stepInfo.step)}
              >
                <div className="d-flex align-items-center">
                  <i className={`${stepInfo.icon} h4 mb-0 me-2 ${currentStep === index ? "text-primary" : completedSteps.has(stepInfo.step) ? "text-gray-900" : "text-gray-600"}`} />
                  <div className={`fs-md ${currentStep === index || completedSteps.has(stepInfo.step) ? "text-dark" : "text-gray-700"} text-nowrap`}>
                    {stepInfo.title}
                  </div>
                </div>
                {htmlIf(stepInfo.number !== 1,
                  <div className="avatar avatar-xs me-2">
                    <div className={`avatar-title mb-0 ${completedSteps.has(stepInfo.step) ? "bg-primary" : "bg-gray-300"} rounded-circle text-white h6`}>
                      <i className="ai-check" />
                    </div>
                  </div>
                )}
              </a>
            </div>
          ))}
        </div>
      </>
    )
  }

  const ViewAnonymousDetailEntry = () => (
    <>
      <div className="fs-md fw-semibold">Before you purchase your package, let’s make sure we know how to get in touch with you!</div>
      <div className="row mt-2">
        <div className="col-md-6">
          <h6 className="fw-bold mb-0">First name*</h6>
          <input
            className="form-control mt-1 t--anon-first-name"
            placeholder="Enter first name…"
            value={anonFirstName}
            onChange={(event) => setAnonFirstName(event.target.value)}
          />
          {htmlIf(showErrors && !anonFirstName.length,
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter your first name.</div>
          )}
        </div>
        <div className="col-md-6 mt-2 mt-md-0">
          <h6 className="fw-bold mb-0">Last name*</h6>
          <input
            className="form-control mt-1 t--anon-last-name"
            placeholder="Enter last name…"
            value={anonLastName}
            onChange={(event) => setAnonLastName(event.target.value)}
          />
          {htmlIf(showErrors && !anonLastName.length,
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter your last name.</div>
          )}
        </div>
        <div className="col mt-2">
          <h6 className="fw-bold mb-0">Email address*</h6>
          <input
            className="form-control mt-1 t--anon-email"
            placeholder="Enter email…"
            value={anonEmail}
            onChange={(event) => setAnonEmail(event.target.value)}
          />
          {htmlIf(showErrors && !EmailValidator.validate(anonEmail),
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter a valid email address.</div>
          )}
        </div>
      </div>
    </>
  )

  const ViewPurchasePackagePage = () => {
    function handleSubmitPackageClicked() {
      if (props.isAnonymous) {
        if (EmailValidator.validate(anonEmail) && anonFirstName.length > 0 && anonLastName.length > 0) {
          purchasePackage();
        } else {
          setShowErrors(true);
        }
      } else {
        purchasePackage();
      }
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0 py-2">
            {/* <div className="fs-md">Step 1 of 1</div> */}
            <div className="h2 mb-0 text-dark">Package details</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
              {htmlIf(props.isAnonymous,
                <div className="mb-3 pb-2 border-bottom">
                  {ViewAnonymousDetailEntry()}
                </div>
              )}
              <div className="row">
                <div className="col-md-6">
                    <div className="fs-md fw-semibold text-gray-900">Package</div>
                    <div className="fs-md text-primary text-uppercase">{props.package.name}</div>
                  </div>
                <div className="col-md-6">
                    <div className="fs-md fw-semibold text-gray-900">Advisor</div>
                    <div className="fs-md">{props.advisor.preferredName}</div>
                  </div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">Package Length</div>
                <div className="fs-md">{props.package.numMinutes / 60} hours</div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">Refund Policy</div>
                <div className="fs-md">You may request a refund of any unused hours within {props.package.refundPeriodLengthDays} days of purchase.</div>
              </div>
              <div className="fs-lg fw-bold mt-2 text-dark">Summary</div>
              <div className="mt-1 mb-2 text-dark">
                <div className="row">
                  <div className="col">Total Price</div>
                  <div className="col-auto text-end fw-bold">{formatUSDWithCents(props.package.totalPriceCents)}</div>
                </div>
              </div>
              <div className="mt-3 fs-sm text-dark">
                Clicking below will take you to Stripe to enter payment information.
              </div>
          </div>
          <div className="card-footer border-0">
            <div className="d-flex">
              <button className="btn btn-primary px-6 t--step-1-next t--enter-tags-next"
                onClick={handleSubmitPackageClicked}
                disabled={isSubmitting}
              >
                  Submit & Pay
              </button>
            </div>
            {htmlIf(errorMessage !== null,
              <div className="mt-2 text-danger fw-bold">{errorMessage}</div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="row">
    <div className="col-lg-4 col-xl-3 d-none d-lg-block">
      <div className="sticky-top">
        <ViewProgressNav />
      </div>
    </div>
    <div className="col-12 col-lg-8 col-xl-9">
      {(() => {
        switch (currentStep) {
          case Step.PurchasePackage:
            return ViewPurchasePackagePage();
          default:
            return null;
        }
      })() as React.ReactNode}
    </div>
  </div>
  );
}

export default PackageCheckout;
