import * as React from 'react';

/**
 * Renders the provided HTML element if the `shouldRender` argument is truthy, or returns `null` otherwise.
 *
 * @param shouldRender Any value, for which, if truthy, the element should be rendered.
 * @param html The HTML element to render if `shouldRender` is true.
 * @returns The rendered HTML element or `null`.
 */
export function htmlIf<T>(value: T | null | undefined, html: JSX.Element) {
  return value ? html : null;
}

/**
 * Renders the result of the provided render function with the `maybeValue` argument as its input,
 * if `maybeValue` is not `null` or `undefined`. Otherwise, returns `null`.
 *
 * @param maybeValue The value to render, if not `null` or `undefined`.
 * @param render The render function that should be called with the `maybeValue` argument as input.
 * @returns The rendered output of the `render` function, or `null`.
 */
export function maybeHtml<T>(maybeValue: T | null | undefined, render: (value: T) => React.ReactElement): React.ReactElement {
  return maybeValue != null ? render(maybeValue) : null;
}

/**
 * Formats a multi-paragraph string into a React element with each paragraph as plain text separated by line breaks.
 *
 * @param str - The multi-paragraph string to format.
 * @returns A React element representing the formatted string with each paragraph as plain text separated by line breaks.
 */
export function formatMultiParagraphString(str: string): React.ReactElement {
  if (str === null) {
    return null
  }

  const paragraphs = str.split('\n');

  return (
    <div>
      {paragraphs.map((paragraph, index) => (
        <React.Fragment key={index}>
          {paragraph}
          {index !== paragraphs.length - 1 && <br />}
        </React.Fragment>
      ))}
    </div>
  );
}

/**
 * A Typescript representation of the Rails `parameterize` function, frequently used in URLs.
 * Converts a string to a parameterized version.
 * Replaces non-alphanumeric characters with a separator,
 * removes consecutive separators, and trims leading/trailing separators.
 *
 * @param {string} string - The input string to be parameterized.
 * @param {string} [separator='-'] - (Optional) The separator to be used; defaults to `-`
 * @returns {string} - The parameterized string.
 */
export function parameterize(string: string, separator = '-'): string {
  const trimmedString = string.trim().toLowerCase();
  const sanitizedString = trimmedString.replace(/[^a-z0-9\-]+/gi, separator);
  const normalizedString = sanitizedString.replace(/-{2,}/g, separator);
  const finalString = normalizedString.replace(/^(\-)|(\-)$/g, '');
  return finalString;
};
