import * as React from 'react';
import { useState, useEffect } from 'react';
import { EssayReview, ReviewStatus } from '../Types/Essays';
import ReviewCard from './ReviewCard';

type Props =
  { reviews: EssayReview[]
  }

const ReviewGrid = (props: Props) => {
  const [allReviews, setAllReviews] = useState(props.reviews);
  const [searchText, setSearchText] = useState('');
  const [filteredReviews, setFilteredReviews] = useState(props.reviews);

  // This hook serves to re-sort and re-filter the list of events when the search text is changed
  useEffect(() => {
    const filtered = allReviews.filter((review) => {
      const searchFields = [
        review.advisorName,
        review.packageName,
        review.id.toString()
      ];
      return (
        searchFields.some((field) =>
          field?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });

    setFilteredReviews(filtered);
  }, [searchText])

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchText(query);
  }

  return (
    <>
      {props.reviews.length === 0
      ? <div className="mt-3 fs-lg">You don’t have any essays yet!</div>
      : <>
          <div className="row mt-3 align-items-center">
            <div className="col-md-8">
              <div className="input-group align-items-center p-0 bg-nav-background">
                <div className="input-group-prepend ms-2">
                  <i className="ai-search"></i>
                </div>
                <input
                  className="form-control"
                  type="search"
                  value={searchText}
                  onChange={handleSearchInputChange}
                  placeholder="Search by reviewer name or package…"
                />
              </div>
            </div>
          </div>
          <div className="mt-3 fs-lg fw-semibold">In Progress</div>
          <div className="row mt-3">
            {filteredReviews.filter((review) => (review.status === ReviewStatus.IN_PROGRESS)).map((review) => (
              <div className="col-md-6 col-lg-4 mb-2">
                  <ReviewCard review={review}/>
              </div>
            ))}
          </div>
          <div className="mt-3 fs-lg fw-semibold">Completed</div>
          <div className="row mt-3">
            {filteredReviews.filter((review) => (review.status === ReviewStatus.COMPLETED)).map((review) => (
              <div className="col-md-6 col-lg-4 mb-2">
                  <ReviewCard review={review}/>
              </div>
            ))}
          </div>
          {props.reviews.some((review) => review.status === ReviewStatus.REFUNDED) && (
            <>
              <div className="mt-3 fs-lg fw-semibold">Refunded</div>
              <div className="row mt-3">
                {filteredReviews.filter((review) => review.status === ReviewStatus.REFUNDED).map((review) => (
                  <div className="col-md-6 col-lg-4 mb-2" key={review.id}>
                    <ReviewCard review={review}/>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      }

    </>
  )
}

export default ReviewGrid;
