import * as React from 'react';
import { useState } from 'react';
import { AdvisingMeeting, MeetingStatus } from '../../Types/Advising';
import { htmlIf } from '../../Utils/HTML';
import MeetingCard from './MeetingCard';
import { updateWhere } from '../../Utils/Array';

type Props =
  { meetings: AdvisingMeeting[]
  , isAdmin: boolean
  }

enum Tab
  { Upcoming
  , Pending
  , Completed
  , Cancelled
  }

const MeetingList = (props: Props) => {
  const [meetings, setMeetings] = useState(props.meetings);
  const [tab, setTab] = useState(Tab.Upcoming);
  const pageLoadTime = new Date();

  const isInFuture = (meeting: AdvisingMeeting): boolean => {
    const endTime = new Date(meeting.startAt).getTime() + meeting.scheduledDurationMinutes * 60 * 1000;
    return endTime > pageLoadTime.getTime();
  };

  const filterMeetings = (status: MeetingStatus) => {
    return meetings.filter(meeting => meeting.status === status);
  };

  const sortMeetingsEarlyToLate = (meetings: AdvisingMeeting[]) => {
    return meetings.sort((a, b) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime())
  }

  const sortMeetingsLateToEarly = (meetings: AdvisingMeeting[]) => {
    return meetings.sort((a, b) => new Date(b.startAt).getTime() - new Date(a.startAt).getTime())
  }

  const displayedMeetings = () => {
    switch (tab) {
      case Tab.Upcoming:
        return sortMeetingsEarlyToLate(filterMeetings(MeetingStatus.ACTIVE).filter(isInFuture));
      case Tab.Pending:
        return sortMeetingsEarlyToLate(filterMeetings(MeetingStatus.ACTIVE).filter(meeting => !isInFuture(meeting)));
      case Tab.Completed:
        return sortMeetingsLateToEarly(filterMeetings(MeetingStatus.COMPLETED));
      case Tab.Cancelled:
        return sortMeetingsLateToEarly(filterMeetings(MeetingStatus.CANCELLED).concat(filterMeetings(MeetingStatus.CANCELLED_BY_ADVISOR)));
      default:
        return [];
    }
  };

  const groupMeetingsByDate = (meetings: AdvisingMeeting[]) => {
    return meetings.reduce<{ [key: string]: AdvisingMeeting[] }>((accumulator, meeting) => {
      const dateKey = new Date(meeting.startAt).toDateString();
      if (!accumulator[dateKey]) accumulator[dateKey] = [];
      accumulator[dateKey].push(meeting);
      return accumulator;
    }, {});
  };

  const groupedMeetings = groupMeetingsByDate(displayedMeetings());

  function updateMeeting(updatedMeeting: AdvisingMeeting) {
    setMeetings((prevMeetings) =>
      updateWhere((meeting) => meeting.id === updatedMeeting.id, updatedMeeting, prevMeetings)
    );
  }

  return (
    <>
      <div className="card rounded-4">
        <div className="border-bottom pb-0">
          <ul className="nav nav-tabs card-header-tabs p-3 pb-0">
            {[
              { label: 'Upcoming', value: Tab.Upcoming },
              { label: 'Pending', value: Tab.Pending },
              { label: 'Completed', value: Tab.Completed },
              { label: 'Cancelled', value: Tab.Cancelled }
            ].map(({ label, value }) => (
              <li key={label} className={`nav-item cursor-pointer me-1 ${tab === value ? 'mb-0 border-bottom border-primary' : ''}`}>
                <button
                  className={`btn btn-link nav-link rounded-0 border-0 cursor-pointer p-1 ${tab === value ? 'active' : 'fw-normal'}`}
                  onClick={() => setTab(value)}
                >
                  {label}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="py-0 mb-3">
          {htmlIf(Object.entries(groupedMeetings).length < 1,
            <div className="p-3 text-center">No meetings here!</div>
          )}
          {Object.entries(groupedMeetings).map(([date, sessions]) => (
            <div key={date} className="">
              <div className="bg-gray-100 px-3 py-1 border-bottom border-gray-200">
                <div className="text-uppercase fs-sm fw-semibold">
                  {new Date(date).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </div>
              </div>
              {sessions.map(meeting =>
                <MeetingCard
                  meeting={meeting}
                  isAdmin={props.isAdmin}
                  onUpdate={updateMeeting}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MeetingList;
