import * as React from 'react';
import AdvisingPackages from './Advising/AdvisingPackages';
import AdvisingSessions from './Advising/AdvisingSessions';
import { AdvisingPackage, AdvisingSession } from '../../Types/Advising';

type Props =
  { advisingPackages: AdvisingPackage[]
  , advisingSessions: AdvisingSession[]
  }

const Advising = (props: Props) => (
  <div>
    <AdvisingPackages advisingPackages={props.advisingPackages} />
    <div className="mt-3" />
    <AdvisingSessions advisingSessions={props.advisingSessions} />
  </div>
)

export default Advising;
