import * as React from 'react';
import { useState } from 'react';
import * as API from '../../../Utils/API';
import { formatMultiParagraphString, htmlIf } from '../../../Utils/HTML';
import { DismissibleModal } from '../../../Components/Modal';
import { AdvisingSession } from '../../../Types/Advising';
import { formatUSDWithoutCents } from '../../../Utils/Currency';

type Props =
  { advisingSessions: AdvisingSession[]
  }

enum Modal
  { NoModal
  , EditSessionModal
  }

const allowedSessionLengths = [15, 30, 45, 60, 75, 90, 120]
const defaultSessionLength = 60

export const AdvisingSessions = (props: Props) => {
  const [advisingSessions, setAdvisingSessions] = useState(props.advisingSessions);
  const [editingSessionId, setEditingSessionId] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [modal, setModal] = useState(Modal.NoModal);
  const [name, setName] = useState('')
  const [description, setDescription] = useState('');
  const [amountCents, setAmountCents] = useState(0)
  const [numMinutes, setNumMinutes] = useState(defaultSessionLength)
  const [activeForSale, setActiveForSale] = useState(true)

  function clearStateSessionFields() {
    setEditingSessionId(0);
    setName('');
    setDescription('');
    setAmountCents(0);
    setNumMinutes(defaultSessionLength);
    setActiveForSale(true);
  }

  function closeModalAndResetFields() {
    clearStateSessionFields();
    setModal(Modal.NoModal);
  }

  function createSession() {
    setIsSaving(true);

    const postBody = {
      name: name,
      description: description,
      amountCents: amountCents,
      numMinutes: numMinutes,
      activeForSale: activeForSale
    }

    API.post("advisor_account_advising_create_advising_session_path", postBody).then(function (result) {
      setAdvisingSessions(result['advisingSessions']);
      closeModalAndResetFields();
      setIsSaving(false);
    })
  }

  function startEditingAdvisingSession(advisingSession: AdvisingSession) {
    setEditingSessionId(advisingSession.id);
    setName(advisingSession.name);
    setDescription(advisingSession.description);
    setAmountCents(advisingSession.amountCents);
    setNumMinutes(advisingSession.numMinutes);
    setActiveForSale(advisingSession.activeForSale);
  }

  function saveEdits() {
    setIsSaving(true);

    const postBody = {
      sessionId: editingSessionId,
      name: name,
      description: description,
      amountCents: amountCents,
      numMinutes: numMinutes,
      activeForSale: activeForSale
    }

    API.post("advisor_account_advising_update_advising_session_path", postBody).then(function (result) {
      setAdvisingSessions(result['advisingSessions']);
      clearStateSessionFields();
      setIsSaving(false);
    })
  }

  function deleteSession(advisingSession: AdvisingSession) {
    setIsSaving(true);
    const postBody = { sessionId: advisingSession.id }

    API.post("advisor_account_advising_delete_advising_session_path", postBody).then(function (result) {
      setAdvisingSessions(result['advisingSessions']);
      clearStateSessionFields();
      setIsSaving(false);
    })
  }

  const AmountCentsInput = () => (
    <div className="input-group align-items-center p-0">
      <div className="input-group-prepend ms-2">$</div>
      <input
        className="form-control t--amount-cents" type="number"
        value={amountCents / 100}
        min={0}
        onChange={(e) => setAmountCents(parseInt(e.target.value) * 100)}
      />
    </div>
  )

  const NumMinutesInput = () => (
    <select className="form-select bg-white"
      value={numMinutes} onChange={(e) => setNumMinutes(Number(e.target.value))}
    >
        {allowedSessionLengths.map((option) => (
            <option key={option} value={option}>
                {`${option} minutes`}
            </option>
        ))}
    </select>
  )

  const DescriptionInput = () => (
    <textarea
      onChange={(e) => setDescription(e.target.value)}
      className="form-control"
      placeholder='Describe what this session includes and who it might be suited for…'
      rows={5}
      value={description}
    />
  )

  const NameInput = () => (
    <input
      onChange={(e) => setName(e.target.value)}
      maxLength={50}
      className="form-control"
      placeholder='Enter session title…'
      value={name}
    />
  )

  const ViewModal = () => {
    switch (modal) {
      case Modal.NoModal:
        return null
      case Modal.EditSessionModal:
        return (
          <DismissibleModal
            title={<h4>Add a session</h4>}
            dialogClass="modal-lg"
            body={
              <>
                <div className="fs-md fw-bold mt-2 mb-1">Name</div>
                {NameInput()}
                <div className="fs-md fw-bold mt-2 mb-1">Session Price</div>
                {AmountCentsInput()}
                <div className="fs-md fw-bold mt-2 mb-1">Session Length</div>
                {NumMinutesInput()}
                <div className="fs-md fw-bold mt-2 mb-1">Description</div>
                {DescriptionInput()}

                <button className="btn btn-primary mt-4" onClick={createSession} disabled={isSaving}>
                  Save
                </button>
              </>
            }
            onDismiss={closeModalAndResetFields}
          />
        )
    }
  }

  const SessionCard = (advisingSession: AdvisingSession) => {
    return (
      <div className="card h-100 mb-2">
        <div className="card-body p-3">
          {htmlIf(editingSessionId !== advisingSession.id,
            <div className="text-end">
              <button className="btn btn-link py-0" onClick={() => startEditingAdvisingSession(advisingSession)}>
                <i className="ai-edit-alt text-primary fw-bold me-1" />
                Edit
              </button>
            </div>
          )}

          <div className="fs-md fw-bold">Name</div>
          {editingSessionId === advisingSession.id
            ? NameInput()
            : <div className="">{advisingSession.name}</div>
          }
          <div className="fs-md fw-bold mt-3">Description</div>
          {editingSessionId === advisingSession.id
            ? DescriptionInput()
            : <div className="">{formatMultiParagraphString(advisingSession.description)}</div>
          }
          <div className="fs-md fw-bold mt-3">Session Price</div>
          {editingSessionId === advisingSession.id
            ? AmountCentsInput()
            : <div className="">{formatUSDWithoutCents(advisingSession.amountCents)}</div>
          }
          <div className="fs-md fw-bold mt-3">Session Length</div>
          {editingSessionId === advisingSession.id
            ? NumMinutesInput()
            : <div className="">{advisingSession.numMinutes.toString()} minutes</div>
          }
          <div className="d-flex align-items-center mt-3">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                disabled={editingSessionId !== advisingSession.id}
                onChange={() => setActiveForSale(!activeForSale)}
                checked={editingSessionId !== advisingSession.id ? advisingSession.activeForSale : activeForSale}
              />
            </div>
            <div className="ms-2">
              <h5 className="mb-1">Session Visibility</h5>
              <div className="fs-md">
                {
                  editingSessionId === advisingSession.id ?
                    activeForSale
                      ? "This session is currently available for purchase."
                      : "This session is not available for purchase."
                    :
                    advisingSession.activeForSale
                      ? "This session is currently available for purchase."
                      : "This session is not available for purchase."
                }
              </div>
            </div>
          </div>

          {htmlIf(editingSessionId === advisingSession.id,
            <div className="d-flex justify-content-end">
              <button className="btn btn-link text-danger mt-3" onClick={() => deleteSession(advisingSession)} disabled={isSaving}>
                <i className="ai-trash text-danger fw-bold me-1" />
                Delete
              </button>
              <button className="btn btn-primary mt-3 ms-3 px-4" onClick={() => saveEdits()} disabled={isSaving}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="card">
      {ViewModal()}
      <div className="card-header d-flex align-items-center justify-content-between py-2">
        <h4 className="mb-0">Advising Sessions</h4>
        <button onClick={() => setModal(Modal.EditSessionModal)} className="btn btn-outline-primary btn-sm">
          Add session
        </button>
      </div>
      <div className="card-body row pt-2">
        <div className="fs-md mb-2">
          Advising sessions are single meetings that clients can purchase with you and do not involve
          a longer relationship or the opportunity for offline work.
        </div>
        {advisingSessions?.map((session, index) => (
          <div className="col-lg-6 mb-2" key={index}>
            {SessionCard(session)}
          </div>
        ))}
        <div className="fs-md mt-2">Advising sessions will be displayed in order of ascending length on your profile (shortest to longest).</div>
      </div>
    </div>
  );
}

export default AdvisingSessions;
