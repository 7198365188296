import * as React from 'react';
import { useState } from 'react';
import { EssayReview } from '../Types/Essays';
import { CustomerEssayStatusBadge } from '../Common/StatusBadges';

type Props =
  { review: EssayReview
  }

function reviewDetailsPath(review: EssayReview) {
  return `/essays/review/${review.id}`
}

function formatDate(dateString: string):string {
  const date = new Date(dateString)
  return (
    date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
  )
}

const ReviewCard = (props: Props) => {
  const [review, setReview] = useState(props.review)

  return (
    <a className={`text-decoration-none t--review-id-${review.id}`}
      href={reviewDetailsPath(review)}
    >
      <div className="card h-100 rounded-4">
        <div className="card-body p-3">
          <div className="h6 mb-1">{review.packageName}</div>
          <div className="d-flex align-items-center">
            <div className="avatar avatar-xs">
              <img className="avatar-img rounded-circle" src={review.advisorImageUrl}/>
            </div>
            <div className="fs-sm text-gray-900 ms-1">{review.advisorName}</div>
          </div>
          <div className="fs-xs mt-1">
            Submitted on {formatDate(review.createdAt)}
          </div>
          <div className="d-flex align-items-center justify-content-between mt-1">
            <CustomerEssayStatusBadge review={review}/>
          </div>
        </div>
      </div>
    </a>
  )
}

export default ReviewCard;
