import * as React from 'react';
import { useState } from 'react';
import * as API from '../../../Utils/API';
import { toTitleCase } from '../../../Utils/String';
import { htmlIf } from '../../../Utils/HTML';

type Props =
  { timezone: string
  , officeHours: OfficeHours
  }

type OfficeHours = DaysOfWeek<Period[]>;

type Period =
  { start: string
  , end: string
  }

type DayOfWeek = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

interface DaysOfWeek<T> {
    monday: T;
    tuesday: T;
    wednesday: T;
    thursday: T;
    friday: T;
    saturday: T;
    sunday: T;
}

// Converts the OfficeHours object to an array of tuples with day names and periods,
// e.g., [['Monday', periods], ['Tuesday', periods], ...]
const officeHoursToArray = (officeHours: OfficeHours) => Object.entries(officeHours) as [DayOfWeek, Period[]][];

const Calendars = (props: Props) => {
  const [timezone, setTimezone] = useState(props.timezone);
  const [officeHours, setOfficeHours] = useState(props.officeHours);
  const [isSaving, setIsSaving] = useState(false);

  function saveOfficeHours() {
    setIsSaving(true);

    const formatDay = (day: DayOfWeek, periods: Period[]) => {
      return (periods ?? []).map(period => formatPeriod(day, period));
    }

    const formatPeriod = (day: DayOfWeek, period: Period) => {
      return {
        day: day,
        start: period.start ?? '',
        end: period.end ?? ''
      };
    }

    const formattedPeriods = Object.entries(officeHours)
      .flatMap(([day, periods]) => formatDay(day as DayOfWeek, periods))
      .filter(period => period !== null);

    const postBody = {
      timezone: timezone,
      officeHours: formattedPeriods
    }

    API.post("advisor_account_scheduling_set_office_hours_path", postBody).then(function (result) {
      setIsSaving(false);
    })
  }

  function updateDay<T>(day: DayOfWeek, updateFn: (value: T) => T, days: DaysOfWeek<T>): DaysOfWeek<T> {
    return { ...days, [day.toLowerCase()]: updateFn(days[day.toLowerCase() as keyof DaysOfWeek<T>]) };
  }

  const blankDay: Period[] = [{ start: null, end: null }];

  // These functions, and the approach we take in this file, are probably more functional than necessary,
  // but this code is mostly from ChatGPT and it's unlikely we'll need to edit it much in the future, so I'm
  // fine with it as-is.
  function toggleNotAvailable(day: DayOfWeek, officeHours: OfficeHours): OfficeHours {
    return updateDay(day, periods => periods.length === 0 ? blankDay : [], officeHours);
  }

  function removePeriod(day: DayOfWeek, index: number, officeHours: OfficeHours): OfficeHours {
    return updateDay(day, periods => periods.filter((_, i) => i !== index), officeHours);
  }

  function addPeriod(day: DayOfWeek, officeHours: OfficeHours): OfficeHours {
    return updateDay(day, periods => [...periods, { start: null, end: null }], officeHours);
  }

  function setStart(day: DayOfWeek, index: number, start: string | null, officeHours: OfficeHours): OfficeHours {
    return updateDay(day, periods => periods.map((period, i) => i === index ? { ...period, start } : period), officeHours);
  }

  function setEnd(day: DayOfWeek, index: number, end: string | null, officeHours: OfficeHours): OfficeHours {
    return updateDay(day, periods => periods.map((period, i) => i === index ? { ...period, end } : period), officeHours);
  }

  const ViewPeriod = ({period, day, index}: {period: Period, day: DayOfWeek, index: number}) => {
    return (
      <div className={index === 0 ? "row" : "row mt-3"}>
        <div className="col-auto my-auto">
          <select className="form-select"
            value={period.start}
            onChange={(event) => setOfficeHours(setStart(day, index, event.target.value, officeHours))}
          >
            {htmlIf(period.start === null || period.start === undefined, <option value="">Select…</option>)}
            {timeOptions}
          </select>
        </div>
        <div className="col-auto my-auto">—</div>
        <div className="col-auto my-auto">
          <select className="form-select"
            value={period.end}
            onChange={(event) => setOfficeHours(setEnd(day, index, event.target.value, officeHours))}
          >
            {htmlIf(period.end === null || period.end === undefined, <option value="">Select…</option>)}
            {timeOptions}
          </select>
        </div>
        <div className="col-auto my-auto">
          <button className="btn btn-link p-0" onClick={() => setOfficeHours(addPeriod(day, officeHours))}>
            <i className="ai-circle-plus text-secondary" />
          </button>
          {htmlIf(index > 0,
            <button className="btn btn-link p-0 ms-3" onClick={() => setOfficeHours(removePeriod(day, index, officeHours))}>
              <i className="ai-trash text-secondary" />
            </button>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="w-50">
        <h4 className="">Time zone</h4>
        <select className="form-select t--timezone" value={timezone || ""} onChange={(event) => setTimezone(event.target.value)}>
            {!timezone && (
                <option value="">Select…</option>
            )}
            {timezones.map((tz) => (
                <option key={tz} value={tz}>
                    {tz}
                </option>
            ))}
        </select>
      </div>
      <div className="mt-4">
        <h4 className="">Office hours</h4>
        {officeHoursToArray(officeHours).map(([day, periods], index) => (
          <>
            <div className="row p-3">
              <div className="col-md-3 my-auto">
                <div className="form-check form-switch mb-0">
                  <input
                    className="form-check-input"
                    id={`t--unavailable-day-${index}`}
                    type="checkbox"
                    onChange={() => setOfficeHours(toggleNotAvailable(day, officeHours))}
                    checked={periods?.length > 0}
                  />
                  <label className="form-check-label ms-1" htmlFor={`t--unavailable-day-${index}`}>{toTitleCase(day)}</label>
                </div>
              </div>
              <div className="col-md-9 mt-2 my-md-auto">
                { periods?.length === 0
                ? <span>Unavailable</span>
                : periods.map((period, index) => (
                    <ViewPeriod period={period} day={day} index={index} />
                ))
                }
              </div>
            </div>
          </>
        ))}
      </div>
      <div className="mt-3">
        <button className="btn btn-primary" disabled={timezone === null || timezone === undefined || isSaving} onClick={saveOfficeHours}>
          Save office hours
        </button>
        {htmlIf(timezone === null || timezone === undefined,
          <div className="fs-md text-danger fw-semibold mt-1">Please select a timezone.</div>
        )}
        {htmlIf(Object.values(officeHours).some(periods => periods.some(period => !period.start || !period.end)),
          <div className="fs-md text-danger fw-semibold mt-1">Please select a valid start and end time for all days.</div>
        )}
      </div>
    </>
  )
}

export default Calendars;

// Courtesy of ChatGPT
const timeOptions: React.ReactElement[] = [
  { label: "12:00 am", value: "00:00" },
  { label: "1:00 am", value: "01:00" },
  { label: "2:00 am", value: "02:00" },
  { label: "3:00 am", value: "03:00" },
  { label: "4:00 am", value: "04:00" },
  { label: "5:00 am", value: "05:00" },
  { label: "6:00 am", value: "06:00" },
  { label: "7:00 am", value: "07:00" },
  { label: "8:00 am", value: "08:00" },
  { label: "9:00 am", value: "09:00" },
  { label: "10:00 am", value: "10:00" },
  { label: "11:00 am", value: "11:00" },
  { label: "12:00 pm", value: "12:00" },
  { label: "1:00 pm", value: "13:00" },
  { label: "2:00 pm", value: "14:00" },
  { label: "3:00 pm", value: "15:00" },
  { label: "4:00 pm", value: "16:00" },
  { label: "5:00 pm", value: "17:00" },
  { label: "6:00 pm", value: "18:00" },
  { label: "7:00 pm", value: "19:00" },
  { label: "8:00 pm", value: "20:00" },
  { label: "9:00 pm", value: "21:00" },
  { label: "10:00 pm", value: "22:00" },
  { label: "11:00 pm", value: "23:00" },
  { label: "12:00 am", value: "24:00" }
].map(opt => <option value={opt.value} key={opt.value}>{opt.label}</option>);

const timezones =
  [ "US/Alaska"
  , "US/Aleutian"
  , "US/Arizona"
  , "US/Central"
  , "US/East-Indiana"
  , "US/Eastern"
  , "US/Hawaii"
  , "US/Indiana-Starke"
  , "US/Michigan"
  , "US/Mountain"
  , "US/Pacific"
  , "US/Samoa"
  , "America/Adak"
  , "America/Anchorage"
  , "America/Anguilla"
  , "America/Antigua"
  , "America/Araguaina"
  , "America/Argentina/Buenos_Aires"
  , "America/Argentina/Catamarca"
  , "America/Argentina/ComodRivadavia"
  , "America/Argentina/Cordoba"
  , "America/Argentina/Jujuy"
  , "America/Argentina/La_Rioja"
  , "America/Argentina/Mendoza"
  , "America/Argentina/Rio_Gallegos"
  , "America/Argentina/Salta"
  , "America/Argentina/San_Juan"
  , "America/Argentina/San_Luis"
  , "America/Argentina/Tucuman"
  , "America/Argentina/Ushuaia"
  , "America/Aruba"
  , "America/Asuncion"
  , "America/Atikokan"
  , "America/Atka"
  , "America/Bahia"
  , "America/Bahia_Banderas"
  , "America/Barbados"
  , "America/Belem"
  , "America/Belize"
  , "America/Blanc-Sablon"
  , "America/Boa_Vista"
  , "America/Bogota"
  , "America/Boise"
  , "America/Buenos_Aires"
  , "America/Cambridge_Bay"
  , "America/Campo_Grande"
  , "America/Cancun"
  , "America/Caracas"
  , "America/Catamarca"
  , "America/Cayenne"
  , "America/Cayman"
  , "America/Chicago"
  , "America/Chihuahua"
  , "America/Coral_Harbour"
  , "America/Cordoba"
  , "America/Costa_Rica"
  , "America/Creston"
  , "America/Cuiaba"
  , "America/Curacao"
  , "America/Danmarkshavn"
  , "America/Dawson"
  , "America/Dawson_Creek"
  , "America/Denver"
  , "America/Detroit"
  , "America/Dominica"
  , "America/Edmonton"
  , "America/Eirunepe"
  , "America/El_Salvador"
  , "America/Ensenada"
  , "America/Fort_Wayne"
  , "America/Fortaleza"
  , "America/Glace_Bay"
  , "America/Godthab"
  , "America/Goose_Bay"
  , "America/Grand_Turk"
  , "America/Grenada"
  , "America/Guadeloupe"
  , "America/Guatemala"
  , "America/Guayaquil"
  , "America/Guyana"
  , "America/Halifax"
  , "America/Havana"
  , "America/Hermosillo"
  , "America/Indiana/Indianapolis"
  , "America/Indiana/Knox"
  , "America/Indiana/Marengo"
  , "America/Indiana/Petersburg"
  , "America/Indiana/Tell_City"
  , "America/Indiana/Valparaiso"
  , "America/Indiana/Vevay"
  , "America/Indiana/Vincennes"
  , "America/Indiana/Winamac"
  , "America/Indianapolis"
  , "America/Inuvik"
  , "America/Iqaluit"
  , "America/Jamaica"
  , "America/Jujuy"
  , "America/Juneau"
  , "America/Kentucky/Louisville"
  , "America/Kentucky/Monticello"
  , "America/Knox_IN"
  , "America/Kralendijk"
  , "America/La_Paz"
  , "America/Lima"
  , "America/Los_Angeles"
  , "America/Louisville"
  , "America/Lower_Princes"
  , "America/Maceio"
  , "America/Managua"
  , "America/Manaus"
  , "America/Marigot"
  , "America/Martinique"
  , "America/Matamoros"
  , "America/Mazatlan"
  , "America/Mendoza"
  , "America/Menominee"
  , "America/Merida"
  , "America/Metlakatla"
  , "America/Mexico_City"
  , "America/Miquelon"
  , "America/Moncton"
  , "America/Monterrey"
  , "America/Montevideo"
  , "America/Montreal"
  , "America/Montserrat"
  , "America/Nassau"
  , "America/New_York"
  , "America/Nipigon"
  , "America/Nome"
  , "America/Noronha"
  , "America/North_Dakota/Beulah"
  , "America/North_Dakota/Center"
  , "America/North_Dakota/New_Salem"
  , "America/Ojinaga"
  , "America/Panama"
  , "America/Pangnirtung"
  , "America/Paramaribo"
  , "America/Phoenix"
  , "America/Port-au-Prince"
  , "America/Port_of_Spain"
  , "America/Porto_Acre"
  , "America/Porto_Velho"
  , "America/Puerto_Rico"
  , "America/Rainy_River"
  , "America/Rankin_Inlet"
  , "America/Recife"
  , "America/Regina"
  , "America/Resolute"
  , "America/Rio_Branco"
  , "America/Rosario"
  , "America/Santa_Isabel"
  , "America/Santarem"
  , "America/Santiago"
  , "America/Santo_Domingo"
  , "America/Sao_Paulo"
  , "America/Scoresbysund"
  , "America/Shiprock"
  , "America/Sitka"
  , "America/St_Barthelemy"
  , "America/St_Johns"
  , "America/St_Kitts"
  , "America/St_Lucia"
  , "America/St_Thomas"
  , "America/St_Vincent"
  , "America/Swift_Current"
  , "America/Tegucigalpa"
  , "America/Thule"
  , "America/Thunder_Bay"
  , "America/Tijuana"
  , "America/Toronto"
  , "America/Tortola"
  , "America/Vancouver"
  , "America/Virgin"
  , "America/Whitehorse"
  , "America/Winnipeg"
  , "America/Yakutat"
  , "America/Yellowknife"
  , "Arctic/Longyearbyen"
  , "Asia/Aden"
  , "Asia/Almaty"
  , "Asia/Amman"
  , "Asia/Anadyr"
  , "Asia/Aqtau"
  , "Asia/Aqtobe"
  , "Asia/Ashgabat"
  , "Asia/Ashkhabad"
  , "Asia/Baghdad"
  , "Asia/Bahrain"
  , "Asia/Baku"
  , "Asia/Bangkok"
  , "Asia/Beirut"
  , "Asia/Bishkek"
  , "Asia/Brunei"
  , "Asia/Calcutta"
  , "Asia/Choibalsan"
  , "Asia/Chongqing"
  , "Asia/Chungking"
  , "Asia/Colombo"
  , "Asia/Dacca"
  , "Asia/Damascus"
  , "Asia/Dhaka"
  , "Asia/Dili"
  , "Asia/Dubai"
  , "Asia/Dushanbe"
  , "Asia/Gaza"
  , "Asia/Harbin"
  , "Asia/Hebron"
  , "Asia/Ho_Chi_Minh"
  , "Asia/Hong_Kong"
  , "Asia/Hovd"
  , "Asia/Irkutsk"
  , "Asia/Istanbul"
  , "Asia/Jakarta"
  , "Asia/Jayapura"
  , "Asia/Jerusalem"
  , "Asia/Kabul"
  , "Asia/Kamchatka"
  , "Asia/Karachi"
  , "Asia/Kashgar"
  , "Asia/Kathmandu"
  , "Asia/Katmandu"
  , "Asia/Khandyga"
  , "Asia/Kolkata"
  , "Asia/Krasnoyarsk"
  , "Asia/Kuala_Lumpur"
  , "Asia/Kuching"
  , "Asia/Kuwait"
  , "Asia/Macao"
  , "Asia/Macau"
  , "Asia/Magadan"
  , "Asia/Makassar"
  , "Asia/Manila"
  , "Asia/Muscat"
  , "Asia/Nicosia"
  , "Asia/Novokuznetsk"
  , "Asia/Novosibirsk"
  , "Asia/Omsk"
  , "Asia/Oral"
  , "Asia/Phnom_Penh"
  , "Asia/Pontianak"
  , "Asia/Pyongyang"
  , "Asia/Qatar"
  , "Asia/Qyzylorda"
  , "Asia/Rangoon"
  , "Asia/Riyadh"
  , "Asia/Saigon"
  , "Asia/Sakhalin"
  , "Asia/Samarkand"
  , "Asia/Seoul"
  , "Asia/Shanghai"
  , "Asia/Singapore"
  , "Asia/Taipei"
  , "Asia/Tashkent"
  , "Asia/Tbilisi"
  , "Asia/Tehran"
  , "Asia/Tel_Aviv"
  , "Asia/Thimbu"
  , "Asia/Thimphu"
  , "Asia/Tokyo"
  , "Asia/Ujung_Pandang"
  , "Asia/Ulaanbaatar"
  , "Asia/Ulan_Bator"
  , "Asia/Urumqi"
  , "Asia/Ust-Nera"
  , "Asia/Vientiane"
  , "Asia/Vladivostok"
  , "Asia/Yakutsk"
  , "Asia/Yekaterinburg"
  , "Asia/Yerevan"
  , "Atlantic/Azores"
  , "Atlantic/Bermuda"
  , "Atlantic/Canary"
  , "Atlantic/Cape_Verde"
  , "Atlantic/Faeroe"
  , "Atlantic/Faroe"
  , "Atlantic/Jan_Mayen"
  , "Atlantic/Madeira"
  , "Atlantic/Reykjavik"
  , "Atlantic/South_Georgia"
  , "Atlantic/St_Helena"
  , "Atlantic/Stanley"
  , "Australia/ACT"
  , "Australia/Adelaide"
  , "Australia/Brisbane"
  , "Australia/Broken_Hill"
  , "Australia/Canberra"
  , "Australia/Currie"
  , "Australia/Darwin"
  , "Australia/Eucla"
  , "Australia/Hobart"
  , "Australia/LHI"
  , "Australia/Lindeman"
  , "Australia/Lord_Howe"
  , "Australia/Melbourne"
  , "Australia/NSW"
  , "Australia/North"
  , "Australia/Perth"
  , "Australia/Queensland"
  , "Australia/South"
  , "Australia/Sydney"
  , "Australia/Tasmania"
  , "Australia/Victoria"
  , "Australia/West"
  , "Australia/Yancowinna"
  , "Brazil/Acre"
  , "Brazil/DeNoronha"
  , "Brazil/East"
  , "Brazil/West"
  , "Canada/Atlantic"
  , "Canada/Central"
  , "Canada/East-Saskatchewan"
  , "Canada/Eastern"
  , "Canada/Mountain"
  , "Canada/Newfoundland"
  , "Canada/Pacific"
  , "Canada/Saskatchewan"
  , "Canada/Yukon"
  , "Chile/Continental"
  , "Chile/EasterIsland"
  , "Cuba"
  , "Egypt"
  , "Eire"
  , "Europe/Amsterdam"
  , "Europe/Andorra"
  , "Europe/Athens"
  , "Europe/Belfast"
  , "Europe/Belgrade"
  , "Europe/Berlin"
  , "Europe/Bratislava"
  , "Europe/Brussels"
  , "Europe/Bucharest"
  , "Europe/Budapest"
  , "Europe/Busingen"
  , "Europe/Chisinau"
  , "Europe/Copenhagen"
  , "Europe/Dublin"
  , "Europe/Gibraltar"
  , "Europe/Guernsey"
  , "Europe/Helsinki"
  , "Europe/Isle_of_Man"
  , "Europe/Istanbul"
  , "Europe/Jersey"
  , "Europe/Kaliningrad"
  , "Europe/Kiev"
  , "Europe/Lisbon"
  , "Europe/Ljubljana"
  , "Europe/London"
  , "Europe/Luxembourg"
  , "Europe/Madrid"
  , "Europe/Malta"
  , "Europe/Mariehamn"
  , "Europe/Minsk"
  , "Europe/Monaco"
  , "Europe/Moscow"
  , "Europe/Nicosia"
  , "Europe/Oslo"
  , "Europe/Paris"
  , "Europe/Podgorica"
  , "Europe/Prague"
  , "Europe/Riga"
  , "Europe/Rome"
  , "Europe/Samara"
  , "Europe/San_Marino"
  , "Europe/Sarajevo"
  , "Europe/Simferopol"
  , "Europe/Skopje"
  , "Europe/Sofia"
  , "Europe/Stockholm"
  , "Europe/Tallinn"
  , "Europe/Tirane"
  , "Europe/Tiraspol"
  , "Europe/Uzhgorod"
  , "Europe/Vaduz"
  , "Europe/Vatican"
  , "Europe/Vienna"
  , "Europe/Vilnius"
  , "Europe/Volgograd"
  , "Europe/Warsaw"
  , "Europe/Zagreb"
  , "Europe/Zaporozhye"
  , "Europe/Zurich"
  , "Greenwich"
  , "Hongkong"
  , "Iceland"
  , "Indian/Antananarivo"
  , "Indian/Chagos"
  , "Indian/Christmas"
  , "Indian/Cocos"
  , "Indian/Comoro"
  , "Indian/Kerguelen"
  , "Indian/Mahe"
  , "Indian/Maldives"
  , "Indian/Mauritius"
  , "Indian/Mayotte"
  , "Indian/Reunion"
  , "Iran"
  , "Israel"
  , "Jamaica"
  , "Japan"
  , "Kwajalein"
  , "Libya"
  , "Mexico/BajaNorte"
  , "Mexico/BajaSur"
  , "Mexico/General"
  , "Pacific/Apia"
  , "Pacific/Auckland"
  , "Pacific/Chatham"
  , "Pacific/Chuuk"
  , "Pacific/Easter"
  , "Pacific/Efate"
  , "Pacific/Enderbury"
  , "Pacific/Fakaofo"
  , "Pacific/Fiji"
  , "Pacific/Funafuti"
  , "Pacific/Galapagos"
  , "Pacific/Gambier"
  , "Pacific/Guadalcanal"
  , "Pacific/Guam"
  , "Pacific/Honolulu"
  , "Pacific/Johnston"
  , "Pacific/Kiritimati"
  , "Pacific/Kosrae"
  , "Pacific/Kwajalein"
  , "Pacific/Majuro"
  , "Pacific/Marquesas"
  , "Pacific/Midway"
  , "Pacific/Nauru"
  , "Pacific/Niue"
  , "Pacific/Norfolk"
  , "Pacific/Noumea"
  , "Pacific/Pago_Pago"
  , "Pacific/Palau"
  , "Pacific/Pitcairn"
  , "Pacific/Pohnpei"
  , "Pacific/Ponape"
  , "Pacific/Port_Moresby"
  , "Pacific/Rarotonga"
  , "Pacific/Saipan"
  , "Pacific/Samoa"
  , "Pacific/Tahiti"
  , "Pacific/Tarawa"
  , "Pacific/Tongatapu"
  , "Pacific/Truk"
  , "Pacific/Wake"
  , "Pacific/Wallis"
  , "Pacific/Yap"
  , "Poland"
  , "Portugal"
  , "Singapore"
  , "Turkey"
  ]
