import * as React from 'react';
import { htmlIf, maybeHtml } from '../Utils/HTML';
import { Advisor, advisorEssayReviewPath, advisorProfilePath } from '../Types/Advisor';
import { EssayReturnTimeTier } from '../Types/Essays';
import { formatUSDWithoutCents } from '../Utils/Currency';

type AdvisorCardProps =
  { advisor: Advisor
  , targetBlank?: boolean
  }

export const AdvisorCard = (props: AdvisorCardProps) => {
  return (
    <>
      <a className={`text-decoration-none t--advisor-id-${props.advisor.id}`}
        href={advisorProfilePath(props.advisor.id)}
        target={props.targetBlank ? '_blank' : null}
      >
        <div className="card h-100 rounded-1 border-0 shadow">
          <div className="card-body p-3 d-flex flex-column align-items-stretch">
            <div className="pb-1">
              <div className="avatar avatar-xxl">
                <img className="avatar-img rounded-circle" src={props.advisor.imageUrl} loading='lazy'/>
              </div>
            </div>
            <div className="h5 mt-1 mb-1">{props.advisor.preferredName}</div>
            {maybeHtml(props.advisor.location, (location) => (
              <div className="d-flex align-items-center py-1">
                <i className="ai-map-pin me-1 fs-xl" />
                {location}
              </div>
            ))}
            {maybeHtml(props.advisor.shortEducation, (shortEdu) => (
              <div className="d-flex align-items-top py-1">
                <i className="ai-book me-1 fs-xl" />
                <div className="fs-sm">{shortEdu}</div>
              </div>
            ))}
            <div className="mt-auto bg-gray-200 rounded-3 p-2">
              <div className="row">
                {maybeHtml(props.advisor.yearsOfExperience, (experience) => (
                  <div className="col-6">
                    <div className="fs-sm fw-semibold">Experience</div>
                    <div className="fs-sm">{experience} years</div>
                  </div>
                ))}
                <div className="col-6">
                  <div className="fs-sm fw-semibold">Rating</div>
                  {htmlIf((props.advisor.averageRating !== null && props.advisor.numRatings > 1),
                    <div className="d-flex fs-sm">
                      {props.advisor.averageRating?.toFixed(1)} ({props.advisor.numRatings} reviews)
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </>
  )
}

export type EssayReviewCardProps =
  { advisor: Advisor
  , returnTimeTiers: EssayReturnTimeTier[]
  , reviewStartingAtCents: number
  , targetBlank?: boolean
  }

export const EssayReviewCard = (props: EssayReviewCardProps) => {
  const fastestReturnTime = props.returnTimeTiers.reduce((min, tier) => Math.min(min, tier.returnTimeHours), Infinity);

  return (
    <>
      <a className={`text-decoration-none t--advisor-id-${props.advisor.id}`}
        href={advisorEssayReviewPath(props.advisor.id)}
        target={props.targetBlank ? '_blank' : null}
      >
        <div className="card h-100 rounded-1 border-0 shadow">
          <div className="card-body p-3 d-flex flex-column align-items-stretch">
            <div className="pb-1 d-flex justify-content-between align-items-start position-relative">
              <div className="avatar avatar-xxl">
                <img className="avatar-img rounded-circle" src={props.advisor.imageUrl} loading="lazy" />
              </div>
              {htmlIf(props.returnTimeTiers.length > 0,
                <div className="badge bg-dark fw-bold position-absolute" style={{ top: '0px', right: '0px' }}>
                  ⚡️ {fastestReturnTime} hour return available
                </div>
              )}
            </div>
            <div className="h5 mt-1 mb-1">{props.advisor.preferredName}</div>
            {maybeHtml(props.advisor.shortEducation, (shortEdu) => (
              <div className="d-flex align-items-top py-1">
                <i className="ai-book me-1 fs-xl" />
                <div className="fs-sm">{shortEdu}</div>
              </div>
            ))}
            <div className="d-flex align-items-center py-1">
              <i className="ai-dollar me-1 fs-xl" />
              <div className="fs-md">
                Review starting at
                <span className="fw-semibold"> {formatUSDWithoutCents(props.reviewStartingAtCents ?? 0)}</span>
              </div>
            </div>
            <div className="py-1 mb-1">
              <div className="text-muted fs-sm text-uppercase mb-1">Return time</div>
              <div>
                <div className="d-flex fs-md">
                  <div className="me-2" style={{ minWidth: '90px' }}>Standard:</div>
                  <div>Up to 48 hours</div>
                </div>
                {htmlIf(props.returnTimeTiers.length > 0,
                  <div className="d-flex fs-md">
                    <div className="me-2" style={{ minWidth: '90px' }}>Expedited:</div>
                    <div className="fw-semibold">As fast as {fastestReturnTime} {fastestReturnTime === 1 ? 'hour' : 'hours'}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-auto bg-gray-200 rounded-3 p-2">
              <div className="row">
                {maybeHtml(props.advisor.yearsOfExperience, (experience) => (
                  <div className="col-6">
                    <div className="fs-sm fw-semibold">Experience</div>
                    <div className="fs-sm">{experience} years</div>
                  </div>
                ))}
                <div className="col-6">
                  <div className="fs-sm fw-semibold">Rating</div>
                  {htmlIf((props.advisor.averageRating !== null && props.advisor.numRatings > 1),
                    <div className="d-flex fs-sm">
                      {props.advisor.averageRating?.toFixed(1)} ({props.advisor.numRatings} reviews)
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </>
  )
}
