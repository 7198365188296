import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as API from '../../../Utils/API';
import { htmlIf, formatMultiParagraphString } from '../../../Utils/HTML';
import { DismissibleModal } from '../../../Components/Modal';
import { EssayPackage } from '../../../Types/Essays';
import { formatUSDWithCents } from '../../../Utils/Currency';


type Props =
  { essayPackages: EssayPackage[]
  }

enum Modal
  { NoModal
  , EditPackageModal
  }

export const EssayPackages = (props: Props) => {
  const [essayPackages, setEssayPackages] = useState(props.essayPackages);
  const [editingPackageId, setEditingPackageId] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [modal, setModal] = useState(Modal.NoModal);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [basePriceCents, setBasePriceCents] = useState(0);
  const [pricePerWordCents, setPricePerWordCents] = useState(0);
  const [activeForSale, setActiveForSale] = useState(true);
  const [limitedToPreviousCustomers, setLimitedToPreviousCustomers] = useState(false);

  function clearStatePackageFields() {
    setEditingPackageId(0);
    setDescription('');
    setBasePriceCents(0);
    setPricePerWordCents(0);
    setActiveForSale(true);
    setLimitedToPreviousCustomers(false);
    setName('');
  }

  function closeModalAndResetFields() {
    clearStatePackageFields();
    setModal(Modal.NoModal);
  }

  function createPackage() {
    setIsSaving(true);

    const postBody = {
      basePriceCents: basePriceCents,
      description: description,
      pricePerWordCents: pricePerWordCents,
      activeForSale: activeForSale,
      limitedToPreviousCustomers: limitedToPreviousCustomers,
      name: name
    }

    API.post("advisor_account_essays_create_essay_package_path", postBody).then(function (result) {
      setEssayPackages(result['essayPackages']);
      closeModalAndResetFields();
      setIsSaving(false);
    })
  }

  function startEditingEssayPackage(essayPackage: EssayPackage) {
    setEditingPackageId(essayPackage.id)
    setDescription(essayPackage.description);
    setBasePriceCents(essayPackage.basePriceCents)
    setPricePerWordCents(essayPackage.pricePerWordCents)
    setName(essayPackage.name)
    setActiveForSale(essayPackage.activeForSale)
    setLimitedToPreviousCustomers(essayPackage.isLimitedToPreviousCustomers)
  }

  function saveEdits() {
    setIsSaving(true);

    const postBody = {
      packageId: editingPackageId,
      description: description,
      basePriceCents: basePriceCents,
      pricePerWordCents: pricePerWordCents,
      name: name,
      activeForSale: activeForSale,
      limitedToPreviousCustomers: limitedToPreviousCustomers
    }

    API.post("advisor_account_essays_update_essay_package_path", postBody).then(function (result) {
      setEssayPackages(result['essayPackages']);
      clearStatePackageFields();
      setIsSaving(false);
    })
  }

  function deletePackage(essayPackage: EssayPackage) {
    setIsSaving(true);
    const postBody = { packageId: essayPackage.id }

    API.post("advisor_account_essays_delete_essay_package_path", postBody).then(function (result) {
      setEssayPackages(result['essayPackages']);
      clearStatePackageFields();
      setIsSaving(false);
    })
  }

  const BasePriceCentsInput = () => (
    <div className="input-group align-items-center p-0">
      <div className="input-group-prepend ms-2">$</div>
      <input
        className="form-control t--total-price-cents" type="number"
        value={basePriceCents / 100}
        min={0}
        onChange={(e) => setBasePriceCents(parseInt(e.target.value) * 100)}
      />
    </div>
  )

  const PricePerWordCentsInput = () => (
    <div className="input-group align-items-center p-0">
      <div className="input-group-prepend ms-2">¢</div>
      <input
        className="form-control t--total-price-cents" type="number"
        value={pricePerWordCents}
        min={0}
        onChange={(e) => setPricePerWordCents(parseInt(e.target.value))}
      />
    </div>
  )

  const DescriptionInput = () => (
    <textarea
      onChange={(e) => setDescription(e.target.value)}
      className="form-control"
      placeholder='Describe what this type of review includes…'
      rows={5}
      value={description}
    />
  )

  const NameInput = () => (
    <input
      onChange={(e) => setName(e.target.value)}
      maxLength={30}
      className="form-control"
      placeholder='Enter package name...'
      value={name}
    />
  )

  const ViewModal = () => {
    switch (modal) {
      case Modal.NoModal:
        return null
      case Modal.EditPackageModal:
        return (
          <DismissibleModal
            title={<h4>Add a package</h4>}
            dialogClass="modal-lg"
            body={
              <>
                <div className="fs-md fw-bold mt-2 mb-1">Name</div>
                {NameInput()}
                <div className="fs-md fw-bold mt-2 mb-1">Base Price</div>
                {BasePriceCentsInput()}
                <div className="fs-md fw-bold mt-2 mb-1">Price Per Word</div>
                {PricePerWordCentsInput()}
                <div className="fs-md fw-bold mt-2 mb-1">Description</div>
                {DescriptionInput()}

                <div className="mt-3 d-flex align-items-center">
                  <div className="mt-1 form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={activeForSale}
                      onChange={() => setActiveForSale(!activeForSale)}
                    />
                  </div>
                  <div className="ms-2">
                    <h5 className="mb-1">Package Visibility</h5>
                    <div className="fs-md">
                      {activeForSale
                        ? "This package is currently available for sale"
                        : "This package is not available for sale"
                      }
                    </div>
                  </div>
                </div>
                <div className="mt-3 d-flex align-items-center">
                  <div className="mt-1 form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={limitedToPreviousCustomers}
                      onChange={() => setLimitedToPreviousCustomers(!limitedToPreviousCustomers)}
                    />
                  </div>
                  <div className="ms-2">
                    <h5 className="mb-1">Is this a repeat review package?</h5>
                    <div className="fs-md">
                      {limitedToPreviousCustomers
                      ? "This package is only available for customers who have already purchased an essay review with you."
                      : "This package is available to all customers."
                      }
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary mt-4" onClick={createPackage} disabled={isSaving}>
                  Save
                </button>
              </>
            }
            onDismiss={closeModalAndResetFields}
          />
        )
    }
  }

  const PackageCard = (essayPackage: EssayPackage) => {
    return (
      <div className="card h-100 mb-2">
        <div className="card-body p-3">
          {htmlIf(editingPackageId !== essayPackage.id,
            <div className="text-end">
              <button className="btn btn-link py-0" onClick={() => startEditingEssayPackage(essayPackage)}>
                <i className="ai-edit-alt text-primary fw-bold me-1" />
                Edit
              </button>
            </div>
          )}
          <div className="fs-md fw-bold">Name</div>
          {editingPackageId === essayPackage.id
            ? NameInput()
            : <div className="">{essayPackage.name}</div>
          }
          <div className="fs-md fw-bold mt-3">Description</div>
          {editingPackageId === essayPackage.id
            ? DescriptionInput()
            : <div className="">{formatMultiParagraphString(essayPackage.description)}</div>
          }
          <div className="fs-md fw-bold mt-3">Base Price</div>
          {editingPackageId === essayPackage.id
            ? BasePriceCentsInput()
            : <div className="">{formatUSDWithCents(essayPackage.basePriceCents)}</div>
          }
          <div className="fs-md fw-bold mt-3">Price Per Word</div>
          {editingPackageId === essayPackage.id
            ? PricePerWordCentsInput()
            : <div className="">{formatUSDWithCents(essayPackage.pricePerWordCents)}</div>
          }
          <div className="d-flex align-items-center mt-3">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                disabled={editingPackageId !== essayPackage.id}
                onChange={() => setActiveForSale(!activeForSale)}
                checked={editingPackageId !== essayPackage.id ? essayPackage.activeForSale : activeForSale}
              />
            </div>
            <div className="ms-2">
              <h5 className="mb-1">Package Visibility</h5>
              <div className="fs-md">
                {
                  editingPackageId === essayPackage.id ?
                    activeForSale
                      ? "This package is currently available for purchase."
                      : "This package is not available for purchase."
                    :
                    essayPackage.activeForSale
                      ? "This package is currently available for purchase."
                      : "This package is not available for purchase."
                }
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mt-3">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                disabled={editingPackageId !== essayPackage.id}
                onChange={() => setLimitedToPreviousCustomers(!limitedToPreviousCustomers)}
                checked={editingPackageId !== essayPackage.id ? essayPackage.isLimitedToPreviousCustomers : limitedToPreviousCustomers}
              />
            </div>
            <div className="ms-2">
              <h5 className="mb-1">Is this a repeat review package?</h5>
              <div className="fs-md">
                {
                  editingPackageId === essayPackage.id ?
                    limitedToPreviousCustomers
                      ? "This package is only available for customers who have already purchased an essay review with you."
                      : "This package is available to all customers."
                    :
                    essayPackage.isLimitedToPreviousCustomers
                      ? "This package is only available for customers who have already purchased an essay review with you."
                      : "This package is available to all customers."
                }
              </div>
            </div>
          </div>

          {htmlIf(editingPackageId === essayPackage.id,
            <div className="d-flex justify-content-end">
              <button className="btn btn-link text-danger mt-3" onClick={() => deletePackage(essayPackage)} disabled={isSaving}>
                <i className="ai-trash text-danger fw-bold me-1" />
                Delete
              </button>
              <button className="btn btn-primary mt-3 ms-3 px-4" onClick={() => saveEdits()} disabled={isSaving}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="card">
      {ViewModal()}
      <div className="card-header d-flex align-items-center justify-content-between py-2">
        <h4 className="mb-0">Essay Packages</h4>
        <button onClick={() => setModal(Modal.EditPackageModal)} className="btn btn-outline-primary btn-sm">
          Add package
        </button>
      </div>
      <div className="card-body row">
        {essayPackages.map((pkg, index) => (
          <div className="col-lg-6 mb-2" key={index}>
            {PackageCard(pkg)}
          </div>
        ))}
        <div className="fs-md mt-2">Essay packages will be displayed in alphabetical order on your profile.</div>
      </div>
    </div>
  );
}

export default EssayPackages;
