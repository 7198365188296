import * as React from 'react';
import { useState } from 'react';
import * as API from '../../Utils/API';
import { AdvisingMeeting, MeetingStatus } from '../../Types/Advising';
import { htmlIf, maybeHtml } from '../../Utils/HTML';
import { DismissibleModal } from '../../Components/Modal';

type Props =
  { meeting: AdvisingMeeting
  , isAdmin: boolean
  , onUpdate: (updatedMeeting: AdvisingMeeting) => void;
  }

enum Modal
  { NoModal
  , MarkComplete
  }

const MeetingCard = (props: Props) => {
  const meeting = props.meeting;
  const pageLoadTime = new Date();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [modal, setModal] = useState(Modal.NoModal);
  const [sessionNotes, setSessionNotes] = useState('');

  const isInFuture = (meeting: AdvisingMeeting): boolean => {
    const endTime = new Date(meeting.startAt).getTime() + meeting.scheduledDurationMinutes * 60 * 1000;
    return endTime > pageLoadTime.getTime();
  };

  function markComplete() {
    setIsSaving(true);

    const postBody = {
      meetingId: meeting.id,
      sessionNotes: sessionNotes
    }

    API.post("advisor_meetings_mark_meeting_complete_path", postBody).then(function (result) {
      const updatedMeeting = {
        ...meeting,
        status: MeetingStatus.COMPLETED,
        sessionNotes: sessionNotes,
      };

      setSessionNotes('');
      setModal(Modal.NoModal);
      props.onUpdate(updatedMeeting);
      setIsSaving(false);
    })
  }

  const ViewModal = () => {
    switch (modal) {
      case Modal.NoModal:
        return null
      case Modal.MarkComplete:
        return (
          <DismissibleModal
            dialogClass='modal-lg'
            title={<h4>Mark session complete</h4>}
            body={
              <div>
                Send a follow-up message to your client with notes about your meeting.
                <textarea
                  className='form-control mt-2 t--session-notes'
                  value={sessionNotes}
                  onChange={(event) => setSessionNotes(event.target.value)}
                  rows={8}
                  placeholder='What did you discuss during your call? What follow-ups are required?'
                />
                <div className="mt-3 text-end">
                  <button className="btn btn-outline-danger px-6 me-2" onClick={() => setModal(Modal.NoModal)}>
                    Cancel
                  </button>
                  <button className="btn btn-primary px-2" onClick={markComplete}>
                    <i className="ai-circle-check me-1" />
                    Mark meeting complete
                  </button>
                </div>
              </div>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
    }
  }

  return (
    <div key={meeting.id} className={`t--meeting-id-${meeting.id}`}>
      {ViewModal()}
      <div className="px-3 py-2 border-bottom">
        <div className="d-flex justify-content-between align-items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
          <div className="">
            <div className="fw-semibold">Session with {meeting.purchase.customerName}</div>
            {new Date(meeting.startAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})} | {meeting.scheduledDurationMinutes} min
          </div>
          <i className={`fs-xl ${isExpanded ? 'ai-chevron-up' : 'ai-chevron-down'}`} />
        </div>
        {htmlIf(isExpanded,
          <div className="mt-3">
            {htmlIf(props.isAdmin,
              <div className="mb-2 bg-gray px-2 py-1 rounded-2">
                Advising Meeting ID: {meeting.id}  — 
                Advising Purchase ID: {meeting.purchase.id}
              </div>
            )}
            <div className="fw-semibold">Session Goals</div>
            <div className="">{meeting.sessionGoals || 'None provided'}</div>
            {maybeHtml(meeting.sessionNotes, (sessionNotes =>
              <>
                <div className="fw-semibold mt-3">Session Notes</div>
                <div className="">{sessionNotes}</div>
              </>
            ))}
            {htmlIf(meeting.status === MeetingStatus.ACTIVE,
              <div className="mt-3">
                {maybeHtml(meeting.zoomJoinUrl, (zoomUrl =>
                  <a className="btn btn-info bg-zoom-blue border-info me-2 px-2" href={zoomUrl} target={'_blank'}>
                    <i className="ai-video me-1" />
                    Join Zoom meeting
                  </a>
                ))}
                {htmlIf(!isInFuture(meeting),
                  <button className="btn btn-primary px-2" onClick={() => setModal(Modal.MarkComplete)}>
                    <i className="ai-circle-check me-1" />
                    Mark meeting complete
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
};

export default MeetingCard;
