import * as React from 'react';
import { EssayReview, ReviewStatus } from '../Types/Essays';

const EssayReviewStandardReturnTimeHours = 48

/**
  * A component that displays a status badge based on the given essay review status.
  * This should only be used when essay reviews are being displayed to a Customer. For
  * displaying requests to Advisors, use AdvisorEssayStatusBadge instead.
  * @param {string} status - The status of the essay review.
  * @return {JSX.Element} - The status badge element.
  * @example <CustomerEssayStatusBadge status={ReviewStatus.IN_PROGRESS} />
*/
export const CustomerEssayStatusBadge = ({review}: {review: EssayReview}) => {
  switch(review.status) {
    case ReviewStatus.AWAITING_PAYMENT:
      return <div className="badge bg-faded-warning text-warning fs-xs">Awaiting Payment</div>
    case ReviewStatus.IN_PROGRESS:
      if (review.guaranteedReturnTimeHours > 0) {
        const paidAtTime = new Date(review.paidAt).getTime();
        const dueTime = paidAtTime + (review.guaranteedReturnTimeHours * 3600000);
        const timeRemaining = dueTime - Date.now();
        const hours = Math.ceil(timeRemaining / 3600000);
        const formattedDuration = `${hours} hours`;

        return (
          <div className="d-flex align-items-center">
            <div className="badge bg-faded-info text-info fs-xs">In Progress</div>
            <div className="badge bg-yellow-300 text-dark fs-xs ms-1">
              ⏰ { timeRemaining > 0 ? `Ready in ${formattedDuration}` : 'Ready soon' }
            </div>
          </div>
        )
      } else {
        return <div className="badge bg-faded-info text-info fs-xs">In Progress</div>
      }
    case ReviewStatus.COMPLETED:
      if (review.readAt) {
        return <div className="badge bg-faded-success text-success fs-xs">Completed</div>
      } else {
        return <div className="badge bg-success text-white fs-xs">Your essay is ready!</div>
      }
    case ReviewStatus.REFUNDED:
      return <div className="badge bg-faded-danger text-danger fs-xs">Refunded</div>
    default:
      return <div></div>
  }
}

/**
  * A component that displays a status badge based on the given essay review status.
  * This should only be used when essay reviews are being displayed to Advisors. For
  * displaying requests to Customers, use CustomerEssayStatusBadge instead.
  * @param {string} status - The status of the essay review.
  * @return {JSX.Element} - The status badge element.
  * @example <AdvisorEssayStatusBadge status={ReviewStatus.IN_PROGRESS} />
*/
export const AdvisorEssayStatusBadge = ({review}: {review: EssayReview}) => {
  switch(review.status) {
    case ReviewStatus.AWAITING_PAYMENT:
      return <div className="badge bg-faded-warning text-warning fs-xs">Awaiting Payment</div>
    case ReviewStatus.IN_PROGRESS:
      const returnTimeHours = review.guaranteedReturnTimeHours ? review.guaranteedReturnTimeHours : EssayReviewStandardReturnTimeHours

      const paidAtTime = new Date(review.paidAt).getTime();
      const dueTime = paidAtTime + (returnTimeHours * 3600000);
      const timeRemaining = dueTime - Date.now();
      const hours = Math.floor(timeRemaining / 3600000);
      const minutes = Math.floor((timeRemaining % 3600000) / 60000);
      const formattedDuration = `${hours}:${minutes.toString().padStart(2, '0')}`;

      return (
        <div className="d-flex align-items-center">
          <div className="badge bg-faded-info text-info fs-xs">In Progress</div>
          <div className="badge bg-yellow-300 text-dark fs-xs ms-1">
            ⏰ { timeRemaining > 0 ? `Due in ${formattedDuration}` : 'Past Due' }
          </div>
        </div>
      )
    case ReviewStatus.COMPLETED:
      return <div className="badge bg-faded-success text-success fs-xs">Completed</div>
    case ReviewStatus.REFUNDED:
      return <div className="badge bg-faded-danger text-danger fs-xs">Refunded</div>
    default:
      return <div></div>
  }
}
